import React, {
  useEffect,
  useState,
} from 'react';
import VideoPanel from './VideoPanel.js'
import Footer from "../Footer";
import Header from "../Header";

const axios = require('axios').default;

export default function PublicGalleryPage() {
  const [videos, setVideos] = useState([]);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Get displayed videos.
      let res = await axios.get([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public", "videos"].join("/"));

      setProviders(res.data.providers);
      setVideos(res.data.videos);
    }

    fetchData();
  }, []);

  return (
    <div className="publicGalleryPage">
      <Header backgroundColor="transparent"/>
      <div className="publicGalleryVideos">
        <h1>Public Video Gallery</h1>
        <h2 > Most Recent </h2>
        <VideoPanel videos={videos} providers={providers} showPublicGalleryButton={false}/>
      </div>
      <Footer />
    </div>
  )
}