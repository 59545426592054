import React from "react";
import {
  Redirect,
  Route,
} from "react-router-dom";
import {
  useAuth,
} from "./shared";

export default function PrivateAdminRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!auth.initialized) {
          return (
            <h1>
              Loading...
            </h1>
          )
        }

        if (!auth.isAdminSignedIn) {
          return (
            <Redirect
              to={{
                pathname: "/admin/signin",
                state: { from: location }
              }}
            />
          )
        }

        return (children)
      }}
    />
  );
}
