import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  useAuth,
} from "./shared";
import SignUpErrorModal from './SignUpErrorModal';

import googleSigninButton from "../../assets/btn_google_signin_light_normal_web@2x.png";

export default function ProviderSignUpButton() {
  const [showModal, setShowModal] = useState(false);

  let history = useHistory();
  let auth = useAuth();

  let signup = async (e) => {
    e.preventDefault();
    try {
      await auth.providerSignUp();
    } catch (e) {
      console.log("login error, e=", e);
      setShowModal(true);
      return
    }

    history.replace("/provider/signup/username");
  }

  return (
    <div>
      <SignUpErrorModal
        show={showModal}
        dismissModal={() => setShowModal(false)}
        signInLink="/provider/signin"
      />
      <img
        className="signInButton"
        src={googleSigninButton}
        alt="Google signup button"
        onClick={signup}
      />
    </div>
  )
}
