import React, {
  useEffect,
  useState,
} from 'react';
import {
  useLocation,
} from "react-router-dom";
import {
  Tab,
  Col,
  Row,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import MessageList from "../MessageList";
import MessagingBox from "../MessagingBox";

const axios = require('axios').default;

export default function ClientMessagesPage() {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [data, setData] = useState(null);
  const [lastUpdatedMessagesAtMs, setLastUpdatedMessagesAtMs] = useState(Date.now());
  const [selectedCorrespondent, setSelectedCorrespondent] = useState(null);
  const [selectedMessageThread, setSelectedMessageThread] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Get message threads.
      let res = await axios.get([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client", "messages"].join("/"), {
        headers,
      });

      setData(res.data);

      // See if a message thread has been selected by virtue of a provider that was passed in (i.e., the user clicked
      // the "Contact <provider>" button). The provider can come from the following places:
      // (1) location.provider (if the user was already signed in when they clicked the button)
      // (2) location.state.provider (if the user was signed out when they clicked the button)
      let myProvider = location.provider || (location.state && location.state.provider);
      if (typeof myProvider !== "undefined") {
        setSelectedCorrespondent(myProvider);
      }

      // If a message thread is already selected-- either by a passed-in provider, or because the user previously
      // selected it (and this is a data update)-- update the selected message thread.
      for (let i=0; i < res.data.messages.length; i++) {
        const messageThread = res.data.messages[i];
        if (((selectedMessageThread !== null) && (messageThread.id === selectedMessageThread.id)) ||
          ((typeof myProvider !== "undefined") && (messageThread.provider_id === myProvider.id))) {
          setSelectedMessageThread(messageThread);
          break
        }
      }
    }

    fetchData();
  }, [lastUpdatedMessagesAtMs]);  // eslint-disable-line react-hooks/exhaustive-deps

  const onThreadUpdate = async (userType, providerID, message) => {
    // The userType should be "client".
    if (userType !== "client") {
      return
    }

    const data = {
      providerID,
      message,
    };
    await axios.post([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/messages/update"].join("/"),
      data, {
        headers,
      }
    );
    setLastUpdatedMessagesAtMs(Date.now());
  }

  const onThreadSelect = (messageThread) => {
    setSelectedMessageThread(messageThread);
  }

  const renderMessagingBox = () => {
    // Don't render the messaging box if a message thread isn't selected, or if we didn't arrive at the messages page
    // with a pre-selected provider.
    if ((selectedMessageThread === null) && (selectedCorrespondent === null)) {
      return null
    }

    return (
        <MessagingBox
          userType="client"
          messageThread={selectedMessageThread}
          correspondent={selectedCorrespondent}
          onThreadUpdate={onThreadUpdate}
        />
    )
  }

  const renderMessagingContainer = () => {
    if (data === null) {
      return (
        <h4 className="subheaderHint">
          Loading...
        </h4>
      )
    }

    if ((data.messages.length === 0) && (selectedCorrespondent === null)) {
      return (
        <h4 className="subheaderHint">
          No messages...
        </h4>
      )
    }

    return (
      <Tab.Container>
        <Row className="messageListContainer">
          <Col xs={20} lg={2}>
            <MessageList
              userType="client"
              messageThreads={data.messages}
              correspondents={data.providers}
              selectedMessageThread={selectedMessageThread}
              selectedCorrespondent={selectedCorrespondent}
              onThreadSelect={onThreadSelect}
            />
          </Col>
          <Col xs={20} lg={10}>
            <Tab.Content>
              {renderMessagingBox()}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    )
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Message Center
      </h1>
      {renderMessagingContainer()}
    </div>
  )
}