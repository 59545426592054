import React from "react";
import { CookieConsent as ReactCookieConsent } from "react-cookie-consent";

export default function CookieConsent() {
  return (
    <ReactCookieConsent>
      This website uses cookies to enhance the user experience.
    </ReactCookieConsent>
  )
}
