import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
} from 'react-bootstrap';

import Header from "../Header";
import WhatWeDoPanel from "./WhatWeDoPanel";
import TestimonialsPanel from "./TestimonialsPanel";
import GoToPublicVideosPanel from "./GoToPublicVideosPanel";
import Footer from "../Footer";
import CookieConsent from "../CookieConsent";

const axios = require('axios').default;

export default function HomePage() {
  const [whatWeDoPanelRef, setWhatWeDoPanelRef] = useState(null);
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Get featured videos.
      let res = await axios.get([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public", "videos", "featured"].join("/"));

      setProviders(res.data.providers);
      setFeaturedVideos(res.data.featuredVideos);
    }

    fetchData();
  }, []);

  const Lead = () => {
    return (
      <div className="leadText">
        <h1>Compassion, at your fingertips.</h1>
        <h3>Personalized Videos for Emotional Support</h3>
        <div className="leadButton">
          <Button
            size="lg"
            onClick={() => whatWeDoPanelRef.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })}
          >
            Learn More
          </Button>
        </div>
      </div>
    )
  }

  const LeadBottom = () => {
    return (
      <div className="leadBottom">
        <h1>
          Receive emotional support now.
        </h1>
        <h3>
          Here's how to get started:
        </h3>
      </div>
    )
  }

  return (
    <div className="homePageContainer">
      <div className="leadContainer">
        <div className="header">
          <Header />
        </div>
        <Lead />
        <LeadBottom />
      </div>
      <div
        ref={ref => setWhatWeDoPanelRef(ref)}
      >
        <WhatWeDoPanel featuredVideos={featuredVideos} providers={providers}/>
      </div>
      <TestimonialsPanel />
      <GoToPublicVideosPanel />
      <Footer />
      <CookieConsent />
    </div>
  )
}
