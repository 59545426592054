import React, {
  useState,
} from 'react';
import {
  Button,
  Form,
} from "react-bootstrap";

export default function ClientSignUpPrivateVideoRequestForm(props) {
  const [context1, setContext1] = useState("");
  const [context2, setContext2] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonDisabled(true);
    props.onSubmit(context1, context2);
  }

  return (
    <Form
      className="form"
      onSubmit={onSubmit}
    >
      <Form.Group
        controlId="formContext1"
        className="formGroup"
      >
        <Form.Label>What do you want a Virtual Hugs coach to talk about?</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={context1}
          onInput={e => setContext1(e.target.value)}
        />
      </Form.Group>
      <Form.Group
        controlId="formContext2"
        className="formGroup"
      >
        <Form.Label>Is there anything else we should know?</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={context2}
          onInput={e => setContext2(e.target.value)}
        />
      </Form.Group>
      <div className="centeredColumn">
        <Button
          variant="primary"
          type="submit"
          disabled={(submitButtonDisabled || ((context1.length === 0) && (context2.length === 0)))}
        >
          {props.submitButtonText ? props.submitButtonText : "Submit"}
        </Button>
      </div>
    </Form>
  )
}
