import React, {
  useState,
  useRef,
} from 'react';
import {
  useAuth,
} from "../../auth/shared";
import {
  Button,
  Form,
  Modal,
  ProgressBar,
} from 'react-bootstrap';

const axios = require('axios').default;

export default function ProviderNewPublicVideoFormModal(props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const cancelTokenSource = useRef();

  const onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(percentCompleted);
  }

  const onSubmitForm = (e) => {
    const putData = async () => {
      const data = {
        videoTitle: title,
        videoDescription: description,
      };

      cancelTokenSource.current = axios.CancelToken.source();

      try {
        const res = await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/videos/public/new"].join("/"),
          data, {
          headers,
        }
        );
        await axios.put(res.data.presignedUrl, file, {
          onUploadProgress,
          cancelToken: cancelTokenSource.current.token,
        });
      } catch (e) {
        if (!(axios.isCancel(e))) {
          props.onFailure();
        }
        return
      }

      props.onSuccess();
      resetModal();
    }

    e.preventDefault();
    putData();
  }

  const resetModal = () => {
    setTitle("");
    setDescription("");
    setFile("");
    setUploadProgress(0);
  }

  const onCancel = (e) => {
    props.onCancel();
    try {
      if (typeof cancelTokenSource !== typeof undefined && file) {
        cancelTokenSource.current.cancel();
        props.onCancelVideoUpload();
      }
    } catch (e) {
      return
    }
    resetModal();
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Upload New Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            controlId="formFileInput"
            className="formGroup"
          >
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Form.Group>
          <Form.Group
            controlId="formVideoTitle"
            className="formGroup"
          >
            <Form.Label>Video Title: </Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={title}
              onInput={e => setTitle(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
          <Form.Group
            controlId="formVideoDescription"
            className="formGroup"
          >
            <Form.Label>Video Description: </Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={description}
              onInput={e => setDescription(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
        </Form>
        <ProgressBar now={uploadProgress} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmitForm}
          disabled={((file === "") || (title === "") || (description === ""))}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
