import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  useHistory,
} from "react-router-dom";
import {
  Alert,
  Button,
  Table,
} from "react-bootstrap";
import {
  useAuth,
} from "../../auth/shared";
import AdminCreateOutreachVideoModal from './AdminCreateOutreachVideoModal';
import ConfirmationModal from '../ConfirmationModal';
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function AdminOutreachVideosList() {
  const history = useHistory();
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [requests, setRequests] = useState([]);
  const [requestsLastUpdatedAtMs, setRequestsLastUpdatedAtMs] = useState(Date.now());
  const [providers, setProviders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [reqToRemove, setReqToRemove] = useState(null);

  // API

  useEffect(() => {
    const fetchData = async () => {
      // Get providers.
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/users/providers"].join("/"), {
        headers,
      });
      setProviders(res.data);
    }

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      // Get requests.
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach"].join("/"), {
        headers,
      });
      setRequests(res.data.requests);
    }

    fetchData();
  }, [requestsLastUpdatedAtMs]); // eslint-disable-line react-hooks/exhaustive-deps

  // table

  const renderAssignee = (req) => {

    let currentValue = req.provider_id;
    if (currentValue !== "(unassigned)") {
      const index = providers.findIndex(provider => provider.id === req.provider_id);
      if (index !== -1) {
        const provider = providers[index];
        currentValue = [provider.profile.nameFirst, provider.profile.nameLast].join(" ");
      }
    }

    return (currentValue)
  }

  const onConfirmRemove = async () => {
    try {
      await axios.delete([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach", reqToRemove].join("/"), {
        headers,
      });
    } catch (e) {
      console.log("onConfirmRemove: e=", e);
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowConfirmationModal(false);
    setReqToRemove(null);
  }

  const renderActions = (req) => {
    const onClickReturn = async () => {
      // try {
      //   await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private", req.id, "reject"].join("/"), {}, {
      //     headers,
      //   });
      // } catch (e) {
      //   console.log("onClickReturn: e=", e);
      //   return
      // }

      // setRequestsLastUpdatedAtMs(Date.now());
    }

    const onClickApprove = async () => {
      try {
        await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach", req.id, "approve"].join("/"), {}, {
          headers,
        });
      } catch (e) {
        console.log("onClickApprove: e=", e);
        return
      }

      setRequestsLastUpdatedAtMs(Date.now());
    }

    const onClickRemove = async () => {
      setShowConfirmationModal(true);
      setReqToRemove(req.id);
    }

    const actions = [
      (
        <Button
          key={"remove-" + req.id}
          className="button-link"
          onClick={() => onClickRemove(req.id)}
        >
          remove
        </Button>
      ),
    ];

    switch (req.status) {
      case "SUBMITTED":
        actions.unshift(
          <Button
            key={"return-" + req.id}
            className="button-link"
            onClick={() => onClickReturn()}
          >
            return
          </Button>
        );
        actions.unshift(
          <Button
            key={"approve-" + req.id}
            className="button-link"
            onClick={() => onClickApprove()}
          >
            approve
          </Button>
        );
        actions.unshift(
          <Link
            key={"preview-" + req.id}
            to={["/outreach", req.id + "?preview=true"].join("/")}
            target="_blank"
            rel="noopener noreferrer"
          >
            preview
          </Link>
        );
        break
      default:
        break
    }

    return (
      <div className="column">
        {actions}
      </div>
    )
  }

  const renderRow = (i) => {
    const req = requests[i];
    return (
      <tr key={i}>
        <td>{i+1}</td>
        <td key={"recipientName-" + i}>
          {req.name}
        </td>
        <td key={"campaign-" + i}>
          {req.campaign}
        </td>
        <td key={"context-" + i}>
          {req.context}
        </td>
        <td key={"assignedto-" + i}>
          {renderAssignee(req)}
        </td>
        <td key={"status-" + i}>
          {req.status}
        </td>
        <td key={"actions-" + i}>
          {renderActions(req)}
        </td>
      </tr>
    )
  }

  const renderTable = () => {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th key="recipientName">Recipient's name</th>
            <th key="campaign">Campaign</th>
            <th key="context">Context</th>
            <th key="assignedTo">Assigned to</th>
            <th key="status">Status</th>
            <th key="actions">Action(s)</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: requests.length }).map((_, index) => renderRow(index))}
        </tbody>
      </Table>
    )
  }

  const onSuccess = () => {
    setShowModal(false);
    setShowSuccessAlert(true);

    // Update the timestamp, so that the API endpoint to refresh the list of videos is called.
    setRequestsLastUpdatedAtMs(Date.now());
  }

  const onFailure = () => {
    setShowModal(false);
    setShowFailureAlert(true);
  }

  return (
    <div>
      <Button
        className="providerUploadButton"
        onClick={() => setShowModal(true)}
      >
        + Create Video Request
      </Button>
      <ResultAlert
        show={showSuccessAlert}
        variant="success"
        message="The request was created."
        onDismiss={() => setShowSuccessAlert(false)}
      />
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="The request could not be created. Please try again."
        onDismiss={() => setShowFailureAlert(false)}
      />
      {renderTable()}
      <AdminCreateOutreachVideoModal
        show={showModal}
        providers={providers}
        onCancel={() => setShowModal(false)}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        titleText="Are you sure?"
        bodyText="This will set the outreach video request to REMOVED."
        cancelText="Cancel"
        confirmText="Yes"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => onConfirmRemove()}
      />
    </div>
  )
}
