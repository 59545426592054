import React, {
  useState,
} from 'react';
import {
  Button,
  Form,
} from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import {
  msToTime,
} from "../../utils/dateTime";

const Message = (props) => {
  if (((props.userType === "client") && (props.sender === "client")) ||
      ((props.userType === "provider") && (props.sender === "provider"))) {
    // The logged-in user sent this message.
    return (
      <div className="sentMessage">
        <div className="messageBubble blueBubble">
          <p className="message">{props.message}</p>
        </div>
        <p className="alignRight">{msToTime(props.timeSent)}</p>
      </div>
    )
  }

  // The correspondent sent this message.
  return (
    <div className="receivedMessage">
      <div className="messageBubble grayBubble">
        <p className="message">{props.message}</p>
      </div>
      <p>{msToTime(props.timeSent)}</p>
    </div>
  )
}

export default function MessagingBox (props) {
  const [message, setMessage] = useState("");

  let correspondentID = null;
  switch (props.userType) {
    case "client":
      correspondentID = "provider_id";
      break
    case "provider":
      correspondentID = "client_id";
      break
    default:
      break
  }

  const renderMessageThread = () => {
    if (props.messageThread === null || typeof props.messageThread === "undefined") {
      return null
    }

    return (
      <InfiniteScroll className="messageBoxScroll"
        dataLength={props.messageThread.messages.length}
        inverse={true}
        loader={<h4>Loading...</h4>}
      >
        {props.messageThread.messages.map(message =>
          <Message
            key={message.sent_at_ms}
            userType={props.userType}
            sender={message.sender}
            message={message.message}
            timeSent={message.sent_at_ms}
          />
        )}
      </InfiniteScroll>
    )
  }

  const renderMessageForm = () => {
    const onSubmit = (e) => {
      e.preventDefault();
      if (props.messageThread !== null) {
        props.onThreadUpdate(props.userType, props.messageThread[correspondentID], message);
      } else if (props.correspondent !== null) {
        // There is no existing message thread with this correspondent.
        props.onThreadUpdate(props.userType, props.correspondent.id, message);
      }
      setMessage("");
    }

    return (
      <Form
        onSubmit={onSubmit}
      >
        <Form.Group
          controlId="messageForm"
          className="formGroup"
        >
          <Form.Control
            type="text"
            name="message"
            placeholder="Type a message"
            value={message}
            onInput={e => setMessage(e.target.value)}
            onChange={() => { }}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
        >
          Send
        </Button>
      </Form>
    )
  }

  return (
    <div className="messageBoxContainer">
      <div className="messageBox">
        {renderMessageThread()}
      </div>
      {renderMessageForm()}
    </div>
  )
}
