import React, {
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Button,
  Modal,
} from 'react-bootstrap';
import {
  useAuth,
} from "./shared";

const ErrorModal = (props) => {
  const history = useHistory();

  return (
    <Modal
      show={props.show}
      onHide={() => { }}
    >
      <Modal.Header>
        <Modal.Title>Sign-Up Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        An error occurred. Please ensure that you are signing in with the correct credentials. If you have already created an account, please sign in.
      </Modal.Body>
      <Modal.Footer>
      <Button
          onClick={() => history.push("/admin/signin")}>
          Sign In
        </Button>
        <Button
          variant="secondary"
          onClick={() => props.dismissModal()}
        >
          Try again
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function AdminSignUpButton() {
  const [showModal, setShowModal] = useState(false);

  let history = useHistory();
  let auth = useAuth();
  let location = useLocation();
  let from = {
    pathname: location.pathname || "/",
  }

  let signup = async (e) => {
    e.preventDefault();
    try {
      await auth.adminSignUp();
    } catch (e) {
      console.log("login error, e=", e);
      setShowModal(true);
      return
    }

    history.replace(from);
    history.push("/admin/dashboard");
  }

  return (
    <div>
      <ErrorModal
        show={showModal}
        dismissModal={() => setShowModal(false)}
      />
      <Button
        className="button-link"
        onClick={signup}
      >
        Sign up with Google
      </Button>
    </div>
  )
}
