import React from "react";
import {
  Button,
  Modal,
} from 'react-bootstrap';

export default function SuccessModal(props) {
  const renderModalBody = () => {
    if (!props.bodyText) {
      return null
    }

    return (
      <Modal.Body>
        {props.bodyText}
      </Modal.Body>
    )
  }

  return (
    <Modal
      show={props.show}
    >
      <Modal.Header>
        <Modal.Title>{props.titleText}</Modal.Title>
      </Modal.Header>
      {renderModalBody()}
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => props.onDismiss()}
        >
          {(props.buttonText ? props.buttonText : "OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
