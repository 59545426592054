import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";
import {
  useAuth,
} from "./shared";
import SignUpErrorModal from './SignUpErrorModal';
import googleSigninButton from "../../assets/btn_google_signin_light_normal_web@2x.png";

export default function ClientSignUpButton() {
  const [showModal, setShowModal] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  let history = useHistory();
  let auth = useAuth();

  const onClick = (e) => {
    const callEndpoint = async () => {
      try {
        await auth.clientSignUp();
      } catch (e) {
        console.log("login error, e=", e);
        setShowModal(true);
        setSubmitButtonDisabled(false);
        return
      }

      history.replace("/client/signup/acceptTC");
    }

    e.preventDefault();
    setSubmitButtonDisabled(true);
    callEndpoint();
  }

  return (
    <div>
      <SignUpErrorModal
        show={showModal}
        dismissModal={() => setShowModal(false)}
        signInLink="/client/signin"
      />
      <Button
        disabled={submitButtonDisabled}
        onClick={onClick}
        className="imageButton"
      >
        <img
          src={googleSigninButton}
          alt="Google signup button"
        />
      </Button>
    </div>
  )
}
