import React from 'react';
  import {
    ListGroup,
  } from 'react-bootstrap'

export default function MessageList (props) {

  const correspondents = props.correspondents;
  const messageThreads = props.messageThreads;
  const userType = props.userType;

  let correspondentID = null;
  switch (userType) {
    case "client":
      correspondentID = "provider_id";
      break
    case "provider":
      correspondentID = "client_id";
      break
    default:
      break
  }

  const renderNewThread = () => {
    // A new thread is indicated by no selected message thread, and a selected correspondent.
    // (So, if a message thread is selected, or there is no selected correspondent, don't render a listgroup item for
    // a new thread.)
    if (props.selectedMessageThread || (!props.selectedCorrespondent)) {
      return null
    }

    return (
      <ListGroup.Item
        className="messageThreadItem"
        action
        active
        variant="primary"
      >
        {props.selectedCorrespondent.username}
      </ListGroup.Item>
    )
  }

  return (
    <ListGroup>
      {messageThreads.map(messageThread =>
        <ListGroup.Item
          key={messageThread.id}
          className="messageThreadItem"
          action
          active={((props.selectedMessageThread !== null) && (messageThread.id === props.selectedMessageThread.id))}
          variant="primary"
          onClick={() => {
            props.onThreadSelect(messageThread);
          }}
        >
          {correspondents[messageThread[correspondentID]].username}
        </ListGroup.Item>
      )}
      {renderNewThread()}
    </ListGroup>
  )
}
