import React from 'react';
import Header from "../../Header";

export default function ProviderDashboardPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Provider Dashboard
      </h1>
      <ul>
        <li>
          <a href="/provider/public">Public Videos</a>
        </li>
        <li>
          <a href="/provider/outreach">Outreach Videos</a>
        </li>
        <li>
          <a href="/provider/private">Private Videos</a>
        </li>
      </ul>
    </div>
  )
}
