import React from 'react';
import {
  Button,
  Card,
  Modal,
} from 'react-bootstrap';
import {
  useHistory,
  Link,
} from "react-router-dom";


export default function VideoModal(props) {
  const history = useHistory();

  const onClickPublicGallery = () => {
    props.handleClose()
    history.push("/public/gallery")
  }

  // Always render a button to redirect to the public gallery page except when the modal is being viewed from the public gallery page.
  const renderPublicGalleryButton = () => {
    if (!props.showPublicGalleryButton) {
      return null
    }

    return (
      <Button
        variant="primary"
        onClick={onClickPublicGallery}
      >
        Watch more public videos
      </Button>
    )
  }

  const renderProviderProfileButton = () => {
    if (!props.showProviderProfileButton || !props.providerUsername) {
      return null
    }

    const onClickProviderProfile = () => {
      props.handleClose()
      history.push(`/profile/${props.providerUsername}`)
    }

    return(
      <Button
        variant="primary"
        onClick={onClickProviderProfile}
      >
        See more videos from {props.providerUsername}
      </Button>
    )
  }

  const renderProviderUsername = () => {
    if (!props.providerUsername) {
      return null
    }

    return (
      <p>
        Provider:&nbsp;
          <Link
            className="providerProfileLink"
            to={`/profile/${props.providerUsername}`}
          >
            &nbsp;{props.providerUsername}
          </Link>
      </p>
    )
  }

  if (!props.video) {
    return null
  }

  return (
    <Modal
      className="modal"
      show={props.show}
      onHide={props.handleClose}
      size="lg"
    >
      <Modal.Header className="modalHeader">
        <Modal.Title className="modalTitle">
          {props.video.title}
        </Modal.Title>
        <Button
          className="modalCloseButton"
          variant="outline"
          onClick={props.handleClose}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Card className="modalVideoCard">
          <video
            className="modalVideo"
            controls
            controlsList="nodownload"
            autoPlay
            preload="metadata"
          >
            <source
              src={props.video.video_url}
              type="video/mp4"
            />
          </video>
        </Card>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <p>
          {props.video.description}
        </p>
        {renderProviderUsername()}
        {renderPublicGalleryButton()}
        {renderProviderProfileButton()}
      </Modal.Footer>
    </Modal>
  )
}
