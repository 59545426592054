import React from 'react';
import Header from "../Header";
import ClientSignUpButton from "./ClientSignUpButton";

export default function ClientSignUpPage() {

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Member Sign Up
      </h1>
      <div className="signInContainer">
        <p>Create a Virtual Hugs account by signing into Google:</p>
        <ClientSignUpButton />
      </div>
    </div>
  )
}