import React, {
  useEffect,
  useState,
} from 'react';
import {
  Tab,
  Col,
  Row,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import MessageList from "../MessageList";
import MessagingBox from "../MessagingBox";

const axios = require('axios').default;

export default function ProviderMessagesPage() {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [data, setData] = useState(null);
  const [lastUpdatedMessagesAtMs, setLastUpdatedMessagesAtMs] = useState(Date.now());
  const [selectedMessageThread, setSelectedMessageThread] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Get message threads.
      let res = await axios.get([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider", "messages"].join("/"), {
        headers,
      });

      setData(res.data);

      // If a message thread is already selected, update it.
      if (selectedMessageThread !== null) {
        for (let i=0; i < res.data.messages.length; i++) {
          const messageThread = res.data.messages[i];
          if (messageThread.id === selectedMessageThread.id) {
            setSelectedMessageThread(messageThread);
            break
          }
        }
      }
    }

    fetchData();
  }, [lastUpdatedMessagesAtMs]);  // eslint-disable-line react-hooks/exhaustive-deps

  const onThreadUpdate = async (userType, clientID, message) => {
    // The userType should be "provider".
    if (userType !== "provider") {
      return
    }

    const data = {
      clientID,
      message,
    };
    await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/messages/update"].join("/"),
      data, {
        headers,
      }
    );
    setLastUpdatedMessagesAtMs(Date.now());
  }

  const onThreadSelect = (messageThread) => {
    setSelectedMessageThread(messageThread);
  }

  const renderMessagingBox = () => {
    // Don't render the messaging box if a message thread isn't selected.
    if (selectedMessageThread === null) {
      return null
    }

    return (
        <MessagingBox
          userType="provider"
          messageThread={selectedMessageThread}
          onThreadUpdate={onThreadUpdate}
        />
    )
  }

  const renderMessagingContainer = () => {
    if (data === null) {
      return (
        <h3>Loading...</h3>
      )
    }

    return (
      <Tab.Container>
        <Row className="messageListContainer">
          <Col xs={20} lg={2}>
            <MessageList
              userType="provider"
              messageThreads={data.messages}
              correspondents={data.clients}
              selectedMessageThread={selectedMessageThread}
              onThreadSelect={onThreadSelect}
            />
          </Col>
          <Col xs={20} lg={10}>
            <Tab.Content>
              {renderMessagingBox()}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    )
  }

  return (
    <div className="page">
      <Header />
      <h1>
        Provider Messages
      </h1>
      {renderMessagingContainer()}
    </div>
  )
}