import React from 'react';
import {
CardDeck
} from 'react-bootstrap';
import VideoCard from "./VideoCard";

export default function VideoPanel(props) {
  const videos = props.videos;
  const providers = props.providers;

  const isVideosDefined = (typeof(videos) !== 'undefined');

  const renderCardDeck = () => {
    if (isVideosDefined && videos.length !== 0) {
      return (
        <CardDeck className="videoDeck">
          {videos.map(video =>
            <VideoCard
              key={video.id}
              video={video}
              providers={providers}
              showVideoCardFooter={props.showVideoCardFooter}
              showPublicGalleryButton={props.showPublicGalleryButton}
              showProviderProfileButton={props.showProviderProfileButton}
            />
          )}
        </CardDeck>
      )
    }

    return (
      <p className="videoPanelNoVideos">
        No Videos Yet...
      </p>
    )
  }

  return (
    <div className="videoPanel">
      {renderCardDeck()}
    </div>
  )
}