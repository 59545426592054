import React, {
  useState,
} from 'react';
import {
  Link,
} from "react-router-dom";
import {
  Card,
} from 'react-bootstrap';
import VideoModal from './VideoModal';

export default function VideoCard(props) {
  const [showModal, setShowModal] = useState(false);

  const video = props.video;
  const providers = props.providers;
  const providerID = video.creator_id;
  const providerUsername = providers[providerID].username;

  const onTap = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  // no need to render the username in the video card footer on the provider profile page
  const renderVideoCardFooter = () => {
    if (props.showVideoCardFooter === false) {
      return null
    }

    return (
      <Card.Footer className="videoCardFooter">
        <Card.Text>
        <Link className="providerProfileLink" to={`/profile/${providerUsername}`}> {providerUsername}</Link>
        </Card.Text>
      </Card.Footer>
    )
  }

  return (
    <Card
      className="videoCard"
    >
      <VideoModal
        show={showModal}
        handleClose={handleClose}
        video={video}
        providerUsername={providerUsername}
        header={video.title}
        showPublicGalleryButton={props.showPublicGalleryButton}
        showProviderProfileButton={props.showProviderProfileButton}
      />
      <Card.Body className="videoCardBody">
        <Card.Title className="videoCardTitle">{video.title}</Card.Title>
      </Card.Body>
      <Card.Img className="videoCardImage" src={video.thumbnail_url} onClick={onTap} />
      {renderVideoCardFooter()}
    </Card>
  )
}
