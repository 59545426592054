import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export const msToTime = (ms) => {
  const date = new Date(parseInt(ms));
  return timeAgo.format(date, "twitter");
}
