import React from "react";
import {
  Button,
  Modal,
} from 'react-bootstrap';

export default function SignUpErrorModal(props) {
  const onClick = (e) => {
    e.stopPropagation();
    props.dismissModal();
  }

  return (
    <Modal
      show={props.show}
      onHide={() => { }}
    >
      <Modal.Header>
        <Modal.Title>Sign-Up Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        An error occurred. Please ensure that you are signing up with the correct credentials. If you have already created an account, please <a href={props.signInLink}>sign in</a>.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClick}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
