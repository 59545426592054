import React, {
  useEffect,
  useState,
} from 'react';
import Carousel from 'react-gallery-carousel';
import VideoModal from './VideoModal';

export default function WhatWeDoPublicVideoSingleGallery(props) {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);

  const onIndexChange = ({ curIndex }) => {
    setIndex(curIndex);
  }

  const onTap = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  const videos = props.featuredVideos;

  useEffect(() => {
    if (videos.length === 0) {
      return
    }

    setThumbnails((videos.map((video) => ({
      thumbnail: video.thumbnail_url,
      src: video.thumbnail_url,
    }))));

  }, [videos]);

  if (videos.length === 0) {
    return null
  }

  const providers = props.providers;
  const providerID = videos[index].creator_id;
  const providerUsername = providers[providerID].username;

  return (
    <div className="whatWeDoCenteredContainer">
      <VideoModal
        show={showModal}
        handleClose={handleClose}
        video={videos[index]}
        providerUsername={providerUsername}
      />
      <h3> {videos[index].title} </h3>
      <Carousel className="whatWeDoCarousel"
        images={thumbnails}
        hasLeftButton={false}
        hasRightButton={false}
        hasMediaButton={false}
        hasSizeButton={false}
        hasIndexBoard={false}
        hasThumbnails={false}
        isAutoPlaying
        onIndexChange={onIndexChange}
        onTap={onTap}
      />
    </div>
  )
}