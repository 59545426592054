import React from 'react';

export default function ProviderProfileCard(props) {
  if (!props.providerProfile) {
    return null
  }

  return (
    <div className="providerProfileCardContainer">
      <div className="providerProfileCardAvatarContainer">
        <img
          className="providerProfileCardAvatar"
          src={props.providerProfile.imageURL + "=s400-c"}
          alt="Provider profile avatar"
        />
      </div>
      <div className="providerProfileCardBodyContainer">
        <p className="providerProfileCardName">
          <a href={"/profile/" + props.providerProfile.username}>
            {props.providerProfile.nameFirst + " " + props.providerProfile.nameLast}
          </a>
        </p>
        <p className="providerProfileCardBioShortText">{props.providerProfile.bioShort}</p>
      </div>
    </div>
  )
}
