import React from 'react';
import {
  LS_KEY_AUTH_TRIGGER,
} from "../../utils/globals";
import Header from "../Header";
import ClientSignInButton from "../auth/ClientSignInButton";

export default function ClientSignInPage() {
  const authTriggerItem = localStorage.getItem(LS_KEY_AUTH_TRIGGER);
  let authTrigger = null;
  if (authTriggerItem !== null) {
    authTrigger = JSON.parse(authTriggerItem);
  }

  const renderSignInMessage = () => {
    let primaryMessage = null;

    const toPathName = authTrigger && authTrigger.pathname;
    switch (toPathName) {
      case "/client/messages":
        primaryMessage = "A Virtual Hugs account is required to message a provider.";
        break
      default:
        break
    }

    return (
      <div>
        <h3>
          {primaryMessage}
        </h3>
      </div>
    )
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Member Sign In
      </h1>
      {renderSignInMessage()}
      <div className="signInContainer">
        <ClientSignInButton />
      </div>
    </div>
  )
}
