import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
  Form,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";

const axios = require('axios').default;

export default function ClientSignUpAcceptTCPage() {
  const auth = useAuth();
  const history = useHistory();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const callEndpoint = async () => {
    await axios.post([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/acceptTC"].join("/"), {}, {
      headers,
    });

    history.replace("/client/signup/private");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitButtonDisabled(true);
    callEndpoint();
  }

  const onCancel = (e) => {
    e.preventDefault();

    history.replace("/");
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        One more step!
      </h1>
      <div className="formContainer signUpFormContainer">
        <Form
          className="form"
          onSubmit={e => onSubmit(e)}
        >
          <Form.Group
            className="formCheckbox"
            controlId="formBasicCheckbox"
          >
            <Form.Label>Please agree to our <a href="/terms" target="_blank">terms and conditions</a>.</Form.Label>
          </Form.Group>
          <div
            className="multipleButtonsContainer"
          >
            <Button
              className="buttonContainer"
              variant="primary"
              type="submit"
              disabled={submitButtonDisabled}
            >
              I agree
            </Button>
            <Button
              className="buttonContainer"
              variant="secondary"
              type="cancel"
              onClick={onCancel}
            >
              Never mind
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
