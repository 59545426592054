import React from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
  Container,
} from 'react-bootstrap';
import {
  LS_KEY_ACCOUNT_CREATION_SRC,
} from "../../utils/globals";
import Header from "../Header";
import Footer from "../Footer";

export default function SocialInvitePage() {
  const history = useHistory();

  const onClickSignUp = () => {
    localStorage.setItem(LS_KEY_ACCOUNT_CREATION_SRC, "social/invite");
    history.push("/client/signup");
  }

  const Lead = () => {
    return (
      <div className="leadText">
        <h1>Compassion, at your fingertips.</h1>
        <h3>Personalized Videos from</h3>
        <h3>Certified Counselors and Coaches</h3>
      </div>
    )
  }

  return (
    <div className="homePageContainer">
      <div className="socialLeadContainer">
        <div className="header">
          <Header hideMenu />
        </div>
        <Lead />
        <div className="vertical" />
        <div className="centeredColumn">
          <div
            className="socialVideoContainer snapchatFormContainer"
          >
            <h2 className="pageHeader centered">
              A Message from Virtual Hugs
            </h2>
            <Container className="centeredColumn">
              <video
                className="modalVideo"
                controls
                controlsList="nodownload"
                autoPlay
                preload="metadata"
              >
                <source
                  src="https://vh-public-2020-06-30.s3-us-west-2.amazonaws.com/signup_email/signup_email_video.MOV"
                  type="video/mp4"
                />
              </video>
              <div className="spacerVertical" />
            </Container>
            <div className="belowVideoContainer">
              <div className="centeredColumn">
                <Button size="md" onClick={onClickSignUp}>
                  Create Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
