import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ProviderSettingsPage() {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [didFetchSettings, setDidFetchSettings] = useState(false);
  const [bioShortOrig, setBioShortOrig] = useState("");
  const [bioShort, setBioShort] = useState("");
  const [privateVideoPriceCentsOrig, setPrivateVideoPriceCentsOrig] = useState("");
  const [privateVideoPriceDollarsCents, setPrivateVideoPriceDollarsCents] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Get provider settings.
      const res = await axios.get([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/settings"].join("/"), {
        headers,
      });
      const providerSettings = res.data;
      setDidFetchSettings(true);
      setBioShortOrig(providerSettings.profile.bioShort);
      setBioShort(providerSettings.profile.bioShort);
      if (providerSettings.privateVideoPriceCents) {
        setPrivateVideoPriceCentsOrig(parseInt(providerSettings.privateVideoPriceCents));
        setPrivateVideoPriceDollarsCents((parseInt(providerSettings.privateVideoPriceCents) / 100).toFixed(2));
      }
    }

    fetchData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const updateSettings = async () => {
    const data = {
      bioShort,
    };
    if (privateVideoPriceDollarsCents.length > 0) {
      data.privateVideoPriceCents = privateVideoPriceDollarsCents * 100;
    }

    try {
      await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/settings"].join("/"),
        data, {
          headers,
        }
      );
    } catch (e) {
      setShowFailureAlert(true);
      return
    }
    setShowSuccessAlert(true);
    setBioShortOrig(bioShort);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    updateSettings();
  }

  if (!didFetchSettings) {
    return null
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Provider Settings
      </h1>
      <Form
        className="form"
        onSubmit={e => onSubmit(e)}
      >
        <Form.Group
          controlId="formBioShort"
          className="formGroup"
        >
          <Form.Label>Update bio</Form.Label>
          <Col sm={8}>
            <TextareaAutosize
              className="formTextarea"
              name="bioShort"
              placeholder="Update your bio"
              value={bioShort}
              onInput={e => setBioShort(e.target.value)}
              onChange={() => { }}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group
          controlId="formPrivateVideoPriceCents"
          className="formGroup"
        >
          <Form.Label>Private video pricing</Form.Label>
          <InputGroup className="mb-3 formInputSmall">
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              maxLength="6"
              aria-label="Amount"
              value={privateVideoPriceDollarsCents}
              onInput={e => setPrivateVideoPriceDollarsCents(e.target.value)}
              onChange={() => { }}
            />
          </InputGroup>
          <Form.Text className="text-muted">
            <p>
              Clients pay the price you specify here, plus taxes, processing fees (about 3%), and platform fees (about 15%).
            </p>
            <p>
              If no price is configured, a price of $19.00 will be used by default.
            </p>
          </Form.Text>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={(bioShort === bioShortOrig) && (privateVideoPriceDollarsCents * 100 === privateVideoPriceCentsOrig)}
        >
          Update
        </Button>
      </Form>
      <ResultAlert
        show={showSuccessAlert}
        variant="success"
        message="Settings were updated successfully."
        onDismiss={() => setShowSuccessAlert(false)}
      />
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="Settings failed to update. Please try again later."
        onDismiss={() => setShowFailureAlert(false)}
      />
    </div>
  )
}