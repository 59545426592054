import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
  Form,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ProviderSignUpSelectUsernamePage() {
  const auth = useAuth();
  const history = useHistory();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [username, setUsername] = useState("");
  const [usernameInputTextColor, setUsernameInputTextColor] = useState("black");
  const [failureAlertMessage, setFailureAlertMessage] = useState("An error occurred. Please try again.");
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  const updateUsernameAcceptTC = async () => {
    const data = {
      username,
      acceptedTC: true,
    };

    try {
      await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/username_acceptTC"].join("/"),
        data, {
          headers,
        }
      );
    } catch (error) {
      if (error.response && (error.response.status === 403)) {
        setFailureAlertMessage("That username is already taken. Please try another one.");
      }
      setShowFailureAlert(true);
      return
    }

    history.replace("/provider/signup/profile");
  }

  const onSubmit = (e) => {
    e.preventDefault();

    updateUsernameAcceptTC();
  }

  const isOnlyLettersAndNumbers = (str) => {
    return str.match("^[A-Za-z0-9]+$");
  }

  const onUsernameInput = (e) => {
    const myUsername = e.target.value;
    setUsername(myUsername);

    // Validate value.
    if (!isOnlyLettersAndNumbers(myUsername)) {
      setUsernameInputTextColor("red");
      setFailureAlertMessage("No spaces or symbols are allowed in the username.");
      setShowFailureAlert(true);
    } else {
      setUsernameInputTextColor("black");
      setShowFailureAlert(false);
    }
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Choose a username.
      </h1>
      <div className="formContainer signUpFormContainer">
        <Form
          className="form"
          onSubmit={e => onSubmit(e)}
        >
          <Form.Group
            controlId="formUsername"
            className="formGroup"
          >
            <Form.Label>
              Choose a username that you'll use to identify yourself within the Virtual Hugs community. (As a provider, your full name will also be visible to users.)
            </Form.Label>
            <Form.Control
              type="text"
              style={{
                color: usernameInputTextColor,
              }}
              name="username"
              placeholder="Username (no spaces or symbols, numbers ok)"
              value={username}
              onInput={onUsernameInput}
              required
            />
          </Form.Group>
          <Form.Group
            className="formCheckbox"
            controlId="formBasicCheckbox"
          >
            <Form.Check type="checkbox"
              required
              label={(<>I agree to the <a href="/terms" target="_blank">terms and conditions</a>.</>)}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
          >
            Submit
          </Button>
        </Form>
        <ResultAlert
          show={showFailureAlert}
          variant="danger"
          message={failureAlertMessage}
          onDismiss={() => setShowFailureAlert(false)}
        />
      </div>
    </div>
  )
}
