import React from 'react';
import Header from "../../Header";
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";

export default function ClientPaymentCancelPage() {
  const history = useHistory();

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Payment was canceled.
      </h1>
      <div className="nextStepsContainer">
        <div className="nextStepContainer">
          <div className="nextStepText">
            <h4 className="titleMarginBottom">
              Your credit card has not been charged.
              If you would still like a private video, please submit a new request.
              In the meantime, check out some of our public videos to start getting support now.
            </h4>
          </div>
          <div className="centeredColumn">
            <Button
              onClick={() => history.push("/public/gallery")}
            >
              Go to public videos
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
