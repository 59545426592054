import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";
import {
  useAuth,
} from "./shared";
import {
  LS_KEY_AUTH_TRIGGER,
} from "../../utils/globals";
import SignInErrorModal from './SignInErrorModal';
import googleSigninButton from "../../assets/btn_google_signin_light_normal_web@2x.png";

export default function ClientSignInButton() {
  const [showModal, setShowModal] = useState(false);

  let history = useHistory();
  let auth = useAuth();

  let login = async (e) => {
    e.preventDefault();
    let client = null;
    try {
      client = await auth.clientSignIn();
    } catch (e) {
      console.log("login error, e=", e);
      setShowModal(true);
      return
    }

    if (!client.accepted_tc_at_ms) {
      // The client hasn't accepted the T&C.
      history.replace("/client/signup/acceptTC");
      return
    }

    const authTriggerItem = localStorage.getItem(LS_KEY_AUTH_TRIGGER);
    if (authTriggerItem !== null) {
      // Go to the authenticated page that the user tried to access, before they were intercepted by the signin process.
      const authTrigger = JSON.parse(authTriggerItem);
      localStorage.removeItem(LS_KEY_AUTH_TRIGGER);
      history.replace(authTrigger.pathname, authTrigger);
      return
    }

    // Go to the client dashboard.
    history.replace("/client/dashboard");
  }

  return (
    <div>
      <SignInErrorModal
        show={showModal}
        dismissModal={() => setShowModal(false)}
        signUpLink="/client/signup"
      />
      <h4>Sign in to your Virtual Hugs account here:</h4>
      <img
        className="signInButton"
        src={googleSigninButton}
        alt="Google signin button"
        onClick={login}
      />
      <p>Don't have an account? Sign up&nbsp;
        <Button
          className="button-link"
          onClick={() => history.replace("/client/signup")}
        >
          here
        </Button>.
      </p>
      <p>Providers, sign in&nbsp;
        <Button
          className="button-link"
          onClick={() => history.replace("/provider/signin")}
        >
          here
        </Button>.
      </p>
    </div>
  )
}
