import React from 'react';

export default function ProviderProfilePhotoName(props) {
  if (!props.providerProfile) {
    return null
  }

  return (
    <div className="providerProfileCardContainer">
      <div className="providerProfileCardAvatarContainer">
        <img
          className="providerProfileCardAvatar"
          src={props.providerProfile.imageURL + "=s400-c"}
          alt="Provider profile avatar"
        />
      </div>
      <div className="providerProfileCardBodyContainer">
        <p className="providerProfileCardName">{props.providerProfile.nameFirst + " " + props.providerProfile.nameLast}</p>
      </div>
    </div>
  )
}
