import React from 'react';
import Header from "../../Header";
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";

export default function ProviderSignUpSuccessPage() {
  const history = useHistory();

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Success! You've created your Virtual Hugs account.
      </h1>
      <div className="signInContainer">
        <Button
          onClick={() => history.replace("/provider/dashboard")}
        >
          Take me to my dashboard
        </Button>
      </div>
    </div>
  )
}
