import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Table,
} from 'react-bootstrap'
import {
  useAuth,
} from "../../auth/shared";
import ConfirmationModal from "../ConfirmationModal";

const axios = require('axios').default;

export default function AdminProvidersList (props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [providers, setProviders] = useState([]);
  const [lastUpdatedAtMsProviders, setLastUpdatedAtMsProviders] = useState(Date.now());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Get providers.
      let res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/users/providers"].join("/"), {
        headers,
      });
      setProviders(res.data);
    }

    fetchData();
  }, [lastUpdatedAtMsProviders]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickDelete = (provider) => {
    setProviderToDelete(provider);
    setShowConfirmationModal(true);
  }

  const onConfirm = () => {
    const deleteProvider = async () => {
      try {
        await axios.delete([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/users/provider", providerToDelete.id].join("/"), {
          headers,
        });
      } catch (e) {
        console.log("deleteProvider: e=", e);
        // setShowFailureAlert(true);
        return
      }

      // setShowRemoveVideoSuccessModal(true);
      setLastUpdatedAtMsProviders(Date.now());
    }

    setShowConfirmationModal(false);
    deleteProvider();
  }

  const renderRow = (i) => {
    const provider = providers[i];
    return (
      <tr key={i}>
        <td>{i+1}</td>
        <td key={"username-" + i}>
          {provider.username}
        </td>
        <td key={"delete-" + i}>
          <Button
            className="button-link"
            onClick={() => onClickDelete(provider)}
          >
            delete
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th key="username">Username</th>
            <th key="delete">Delete user</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: providers.length }).map((_, index) => renderRow(index))}
        </tbody>
      </Table>
      <ConfirmationModal
        show={showConfirmationModal}
        titleText="Are you sure?"
        bodyText="Removing this provider will also remove any message threads the provider has had with clients, and any public videos the provider has created. This action cannot be undone."
        cancelText="Cancel"
        confirmText="Yes, remove provider."
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => onConfirm()}
      />
    </div>
  )
}
