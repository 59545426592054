import React from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  ListGroup,
} from "react-bootstrap";

export default function Footer() {
  const history = useHistory();

  return (
    <footer className="footer">
      <div className="listGroupContainerRow">
        <ListGroup className="listGroupColumn">
          <ListGroup.Item
            className="listGroupItem"
            key="terms"
          >
            <a
              href="/terms"
              className="footerLink"
            >
              Terms & Conditions
            </a>
          </ListGroup.Item>
          <ListGroup.Item
            className="listGroupItem"
            key="privacy"
          >
            <a
              href="/privacy"
              className="footerLink"
            >
              Privacy Policy
            </a>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="listGroupColumn">
        <ListGroup.Item
            className="listGroupItem"
            key="about"
          >
            <a
              href="/about"
              className="footerLink"
            >
              About Us
            </a>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="footerCopyright">
        &copy; 2023 Good Struggle LLC, all rights reserved.
      </div>
    </footer>
  )
}
