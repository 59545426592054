import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Button,
  Table,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import VideoPreviewCard from "../VideoPreviewCard";
import ResultAlert from "../ResultAlert";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "../../SuccessModal";

const axios = require('axios').default;

export default function AdminVideoSettingsPage() {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { videoID } = useParams();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [video, setVideo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [lastUpdatedAtMs, setLastUpdatedAtMs] = useState(Date.now());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRemoveVideoSuccessModal, setShowRemoveVideoSuccessModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", videoID].join("/"), {
        headers,
      });
      setVideo(res.data.video);
    }

    const myVideo = location.video || (location.state && location.state.video);
    if (myVideo && !loaded) {
      setVideo(myVideo);
    } else {
      // The video isn't in location. (This could be because the video settings page was reloaded. Make an API call to fetch the video.)
      fetchVideo();
    }

    // If the video was initially loaded from the location, don't use the location's video on subsequent refreshes.
    setLoaded(true);
  }, [lastUpdatedAtMs]);  // eslint-disable-line react-hooks/exhaustive-deps

  const renderStatus = () => {
    const onClickApprove = async (e) => {
      const url = [process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", video.id, "status"].join("/");
      const data = {
        status: "APPROVED",
      };

      await axios.post(url, data, {
        headers,
      });
      setLastUpdatedAtMs(Date.now());
    }

    const onClickRemove = async (e) => {
      const url = [process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", video.id, "status"].join("/");
      const data = {
        status: "REMOVED_BY_ADMIN",
      };

      await axios.post(url, data, {
        headers,
      });
      setLastUpdatedAtMs(Date.now());
    }

    const renderStatusActions = () => {
      let actions = [];

      // An admin can approve any video, if it hasn't already been approved.
      if (!video.status.startsWith("APPROVED")) {
        actions.push(
          <Button
            key="status-action-remove"
            className="button-link"
            onClick={() => onClickApprove()}
          >
            approve
          </Button>
        )
      }

      // An admin can remove a video if it hasn't already been removed.
      if (!video.status.startsWith("REMOVED")) {
        actions.push(
          <Button
            key="status-action-remove"
            className="button-link"
            onClick={() => onClickRemove()}
          >
            remove
          </Button>
        )
      }

      return (
        <div className="column">
          {actions.map(action => action)}
        </div>
      )
    }

    return (
      <tr key="status">
        <td key="status-name">
          Status
        </td>
        <td key="status-current">
          {video.status}
        </td>
        <td key="status-actions">
          {renderStatusActions()}
        </td>
      </tr>
    )
  }

  const renderDisplayed = () => {
    const onToggleDisplayed = async (e) => {
      const url = [process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", video.id, "setDisplayed"].join("/");
      const displayed = (video.displayed === 0 ? 1 : 0);
      await axios.post(url, {
        displayed,
      }, {
        headers,
      });
      setLastUpdatedAtMs(Date.now());
    }

    const renderDisplayedActions = () => {
      if (video.status !== "APPROVED") {
        return "(status must be set to APPROVED)"
      }

      let buttonText = "display";
      if (video.displayed === 1) {
        buttonText = "stop displaying";
      }

      return (
        <Button
          key="displayed-action-toggle"
          className="button-link"
          onClick={() => onToggleDisplayed()}
        >
          {buttonText}
        </Button>
      )
    }

    return (
      <tr key="displayed">
        <td key="displayed-name">
          Displayed
        </td>
        <td key="displayed-status">
          {video.displayed === 1 ? "yes" : "no"}
        </td>
        <td key="displayed-actions">
          {renderDisplayedActions()}
        </td>
      </tr>
    )
  }

  const renderFeatured = () => {
    const onToggleFeatured = async (e) => {
      const url = [process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", video.id, "setFeatured"].join("/");
      const featured = (video.featured === 0 ? 1 : 0);
      await axios.post(url, {
        featured,
      }, {
        headers,
      });
      setLastUpdatedAtMs(Date.now());
    }

    const renderFeaturedActions = () => {
      if (video.status !== "APPROVED") {
        return "(status must be set to APPROVED)"
      }
      if (video.displayed !== 1) {
        return "(displayed must be set to TRUE)"
      }

      let buttonText = "feature";
      if (video.featured === 1) {
        buttonText = "stop featuring";
      }

      return (
        <Button
          key="featured-action-toggle"
          className="button-link"
          onClick={() => onToggleFeatured()}
        >
          {buttonText}
        </Button>
      )
    }

    return (
      <tr key="featured">
        <td key="featured-name">
          Featured
        </td>
        <td key="featured-status">
          {video.featured === 1 ? "yes" : "no"}
        </td>
        <td key="featured-actions">
          {renderFeaturedActions()}
        </td>
      </tr>
    )
  }

  const onConfirmDeleteVideo = () => {
    const deleteVideo = async () => {
      try {
        await axios.delete([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", video.id].join("/"), {
          headers,
        });
      } catch (e) {
        setShowFailureAlert(true);
        return
      }

      setShowRemoveVideoSuccessModal(true);
    }

    setShowConfirmationModal(false);
    deleteVideo();
  }

  const onDismissRemoveVideoSuccessModal = () => {
    history.replace("/admin/dashboard");
  }

  if (!video) {
    return null
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Video Settings
      </h1>
      <div className="centeredColumn">
        <VideoPreviewCard
          video={video}
        />
      </div>
      <Table responsive>
        <thead>
          <tr key="header-row">
            <th key="attribute">Attribute</th>
            <th key="current-setting">Current Value</th>
            <th key="actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderStatus()}
          {renderDisplayed()}
          {renderFeatured()}
        </tbody>
      </Table>
      <Button
        variant="danger"
        onClick={() => setShowConfirmationModal(true)}
      >
        permanently delete video
      </Button>
      <ConfirmationModal
        show={showConfirmationModal}
        titleText="Are you sure?"
        bodyText="This will permanently delete the video. This action cannot be undone."
        cancelText="Cancel"
        confirmText="Yes, delete."
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => onConfirmDeleteVideo()}
      />
      <SuccessModal
        show={showRemoveVideoSuccessModal}
        titleText="Video Removed"
        onDismiss={onDismissRemoveVideoSuccessModal}
      />
      <ResultAlert
        show={showSuccessAlert}
        variant="success"
        message="The action was successful."
        onDismiss={() => setShowSuccessAlert(false)}
      />
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="The action failed. Please try again later."
        onDismiss={() => setShowFailureAlert(false)}
      />
    </div>
  )
}