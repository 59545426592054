import React, {
  useEffect,
} from 'react';
import {
  useLocation,
} from "react-router-dom";
import Header from "../Header";

const axios = require('axios').default;

export default function UnsubscribePage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const callEndpoint = async (email) => {
      await axios.post([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public/signup/unsubscribe"].join("/"), {
        email,
      });
    }

    const email = atob(params.get("email"));
    callEndpoint(email);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page">
      <Header />
      You have been unsubscribed.
    </div>
  );
}
