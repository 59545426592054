import React from 'react';
import {
  Card,
  CardDeck,
} from 'react-bootstrap';

export default function TestimonialsPanel() {
  return (
    <div className="testimonialsPanel">
      <h1>
        What People Are Saying
      </h1>
      <CardDeck className="testimonialsCardDeck">
        <Card className="testimonialCard">
          <Card.Body className="testimonialCardBody">
            <Card.Title className="testimonialCardTitle">Virtual Hugs are amazing and just what the world needs right now!</Card.Title>
          </Card.Body>
          <Card.Footer className="testimonialCardFooter">
            <Card.Text>
              Trixie Green
            </Card.Text>
          </Card.Footer>
        </Card>
        <Card className="testimonialCard">
          <Card.Body className="testimonialCardBody">
            <Card.Title className="testimonialCardTitle">Hugs are warming gifts. It's simple but really meaningful. In these uncertain times, Virtual Hugs is the solution to give a nice gift from someone really far away from you.</Card.Title>
          </Card.Body>
          <Card.Footer className="testimonialCardFooter">
          <Card.Text>
              Amin Salan
            </Card.Text>
          </Card.Footer>
        </Card>
        <Card className="testimonialCard">
          <Card.Body className="testimonialCardBody">
            <Card.Title className="testimonialCardTitle">I was so surprised to get a Virtual Hug. During one of my the most challenging times in my life, it made me feel so visible, comforted and loved.</Card.Title>
          </Card.Body>
          <Card.Footer className="testimonialCardFooter">
            <Card.Text>
              Kaz Loren
            </Card.Text>
          </Card.Footer>
        </Card>
      </CardDeck>
    </div>
  )
}
