import React from 'react';
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import Header from "../../Header";
import AdminVideosList from "./AdminVideosList";

export default function AdminPublicVideosPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Public Videos
      </h1>
      <Tabs defaultActiveKey="videosPending" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="videosPending" title="Videos: Pending">
          <AdminVideosList type="pending" />
        </Tab>
        <Tab eventKey="videosRemoved" title="Videos: Removed">
          <AdminVideosList type="removed" />
        </Tab>
        <Tab eventKey="videosApproved" title="Videos: Approved">
          <AdminVideosList type="approved" />
        </Tab>
      </Tabs>
    </div>
  )
}
