import React from 'react';
import {
  ListGroup,
} from "react-bootstrap";
import {
  msToTime,
} from "../../utils/dateTime";

export default function VideoTableCell(props) {
  const video = props.video;

  const renderStatusSubtext = () => {
    if (video.featured) {
      return "featured"
    } else if (video.displayed) {
      return "displayed"
    }

    return null
  }

  return (
    <ListGroup.Item
      key={video.id}
      action
      onClick={() => props.onClick(video)}
    >
      <div className="videoTableCellContainer">
        <div className="videoTableVideoContainer">
          <div
            className="videoTableThumbnailImage"
            style={{
              backgroundImage: `url(` + video.thumbnail_url + `)`,
            }}
          />
          <div className="videoTableTextContainer">
            <div className="videoTableTitle">
              {video.title}
            </div>
            <div className="videoTableDescription">
              {video.description}
            </div>
            <div className="videoTableDateContainer">
              <div className="videoTableDate">
                {msToTime(video.created_at_ms)}
              </div>
            </div>
          </div>
        </div>
        <div className="videoTableStatusContainer">
          <div className="videoTableStatusTextContainer">
            {video.status}
          </div>
          <div className="videoTableStatusSubtextContainer">
            {renderStatusSubtext()}
          </div>
        </div>
      </div>
    </ListGroup.Item>
  )
}
