import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {
  Button,
  Container,
} from 'react-bootstrap';
import QRCode from "react-qr-code";
import {
  useQuery,
} from "../../utils/query";
import {
  LS_KEY_ACCOUNT_CREATION_SRC,
  LS_KEY_OUTREACH_REQ_ID,
} from "../../utils/globals";
import OutreachFooter from "./OutreachFooter";
import ProviderProfilePhotoName from '../unauthenticated/ProviderProfilePhotoName'
import ClientSignUpButton from "../auth/ClientSignUpButton";

const axios = require('axios').default;
const saveSvgAsPng = require("save-svg-as-png");

export default function OutreachVideoPage() {
  const history = useHistory();
  const query = useQuery();

  const [request, setRequest] = useState(null);
  const [providerProfile, setProviderProfile] = useState(null);

  const qrCode = useRef(null);

  const {
    reqID,
  } = useParams();
  const preview = query.get("preview");

  useEffect(() => {
    const callEndpoint = async () => {
      let res = null;
      if (preview) {
        res = await axios.get([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public/outreach", reqID].join("/"));
      } else {
        res = await axios.post([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public/outreach", reqID, "view"].join("/"));
      }
      setRequest(res.data.request);
      setProviderProfile(res.data.provider.profile);
    }

    callEndpoint();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem(LS_KEY_ACCOUNT_CREATION_SRC, "outreach");
    localStorage.setItem(LS_KEY_OUTREACH_REQ_ID, reqID);
  })

  const renderHeader = () => {
    if (request.campaign === "topic") {
      return (
        <h1 className="pageHeader centered">
          A Video About {request.name}
        </h1>
      )
    }

    return (
      <h1 className="pageHeader centered">
        A Virtual Hug Video for {request.name}
      </h1>
    )
  }

  const renderProfile = () => {
    if (request.campaign === "topic") {
      return null
    }

    return (
      <div className="providerProfileContainer centered">
        Recorded by:
        <div className="centeredColumn">
          <ProviderProfilePhotoName
            providerProfile={providerProfile}
          />
        </div>
      </div>
    )
  }

  const onClickSignUp = () => {
    const callEndpoint = async () => {
      await axios.post([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public/outreach", reqID, "click"].join("/"));
    }

    callEndpoint();
  }

  const onClickCheckOut = () => {
    const callEndpoint = async () => {
      await axios.post([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public/outreach", reqID, "click"].join("/"));
    }

    callEndpoint();

    history.push("/client/signup");
  }

  const renderCTA = () => {
    if (request.campaign === "topic") {
      return (
        <div className="callToActionContainer">
          <h3>
            Click below to receive a free video recorded just for you by a counselor:
          </h3>
          <div
            className="buttonContainer"
            onClick={onClickSignUp}
          >
            <ClientSignUpButton />
          </div>
        </div>
      )
    }

    return (
      <div className="callToActionContainer">
        <h3>
          Want more emotional support?
        </h3>
        <div className="buttonContainer">
          <Button
            size="md"
            onClick={onClickCheckOut}
          >
            Check out Virtual Hugs
          </Button>
        </div>
      </div>
    )
  }

  const onClickSaveQRCode = () => {
    const filename = ["qr", request.name, request.context].join('-').replace(/[^\w-]/gi, '') + ".png";
    saveSvgAsPng.saveSvgAsPng(qrCode.current, filename, {
      encoderOptions: 1,
      scale: 4,
    });
  }

  const renderPreviewContent = () => {
    if (preview) {
      const url = window.location.href.split('?')[0];
      return (
        <div>
          <ul>
            <li>
              reqID: {reqID}
            </li>
            <li>
              name: {request.name}
            </li>
            <li>
              context: {request.context}
            </li>
          </ul>
          <QRCode
            ref={qrCode}
            size={64}
            style={{ height: "auto", maxWidth: "0%", width: "0%" }}
            value={url}
            viewBox={`0 0 256 256`}
          />
          <Button
            onClick={onClickSaveQRCode}
          >
            save QR code
          </Button>

        </div>
      )
    }

    return null
  }

  if (!request || !providerProfile) {
    return null
  }

  return (
    <div className="pageWithFooter">
      <div className="spacer vertical" />
      {renderHeader()}
      {renderProfile()}
      <Container className="centeredColumn">
        <video
          className="modalVideo"
          autoPlay
          muted
          controls
          controlsList="nodownload"
          preload="metadata"
        >
          <source
            src={request.video_url}
            type="video/mp4"
          />
        </video>
        <div className="spacerVertical" />
      </Container>
      <div className="belowVideoContainer">
        {renderCTA()}
      </div>
      {renderPreviewContent()}
      <OutreachFooter />
    </div>
  )
}
