import React from 'react';
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import Header from "../../Header";
import AdminClientsList from "./AdminClientsList";
import AdminProvidersList from "./AdminProvidersList";

export default function AdminUsersPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Users
      </h1>
      <Tabs defaultActiveKey="clients" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="clients" title="Clients">
          <AdminClientsList />
        </Tab>
        <Tab eventKey="providers" title="Providers">
          <AdminProvidersList />
        </Tab>
      </Tabs>
    </div>
  )
}
