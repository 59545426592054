import React, {
    useEffect,
    useState,
  } from 'react';
import VideoPanel from './VideoPanel.js'
import Footer from "../Footer";
import Header from "../Header";
import {
  Button
} from 'react-bootstrap';
import {
  useParams,
  useHistory,
} from 'react-router-dom';

const axios = require('axios').default;

export default function ProviderProfilePage() {
    const [videos, setVideos] = useState([]);
    const [provider, setProvider] = useState(null);

    // sets the username to the username in the current URL path.
    const { username } = useParams();
    const history = useHistory();

    useEffect(() => {
      const fetchData = async () => {
        // Get displayed videos for specified provider.
        const res = await axios.get([process.env.REACT_APP_API_PUBLIC_ENDPOINT, "public", "videos", username].join("/"));
        setProvider(res.data.provider);  // This is an object of 1 provider entry, indexed by providerID.
        setVideos(res.data.videos);
      }

      fetchData();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    if (provider === null) {
      return null
    }

    const providerID = Object.keys(provider)[0];
    const providerBio = provider[providerID].profile.bioShort;
    const providerAvatar = provider[providerID].profile.imageURL + "=s400-c";
    const providerFirstName = provider[providerID].profile.nameFirst;
    const providerFullName = provider[providerID].profile.nameFirst + " " + provider[providerID].profile.nameLast;

    const onClickRequestPrivateVideo = () => {
      history.push('/client/private' + '?providerID=' + providerID);
    }

    const onClickOtherProviders = () => {
      history.push("/public/gallery")
    }

    return (
    <div className="providerProfilePage">
      <Header backgroundColor="transparent"/>
      <div className="providerProfileSection">
        <div className="providerProfileAvatarContainer">
          <img
            className="providerProfileAvatar"
            src={providerAvatar}
            alt="Provider profile avatar"
          />
        </div>
        <div className="providerProfileBodyContainer">
          <p className="providerProfileName">{providerFullName}</p>
          <p className="providerProfileUsername">{username}</p>
          <div className="providerProfileBioContainer">
            <p className="providerProfileTextHeader">About</p>
            <p className="providerProfileBioText">{providerBio}</p>
          </div>
          <div className="providerProfileMessageButtonContainer">
            <Button className="messageButton" size="md" onClick={onClickRequestPrivateVideo}>
              Request a private video from {providerFirstName}
            </Button>
            <Button className="messageButton" variant="outline-dark" size="md" onClick={onClickOtherProviders}>
              Check out other providers...
            </Button>
          </div>
          <p className="providerProfileTextHeader">{providerFirstName}'s Videos</p>
          <VideoPanel
            className="videoPanel"
            videos={videos}
            providers={provider}
            showVideoCardFooter={false}
            showProviderProfileButton={false}
          />
        </div>
      </div>
      <Footer />
    </div>
    )
}