import React, {
  useState,
} from 'react';
import {
  useAuth,
} from "../../auth/shared";
import {
  Button,
  Form,
  Modal,
  ProgressBar,
} from 'react-bootstrap';

const axios = require('axios').default;

export default function ProviderUploadOutreachVideoModal(props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [file, setFile] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(percentCompleted);
  }

  const onSubmitForm = (e) => {
    const callEndpoint = async () => {
      try {
        // Get the presigned URL.
        const res = await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/outreach", props.request.id, "video"].join("/"), {}, {
          headers,
        });

        // Upload data to the presigned URL.
        await axios.put(res.data.presignedUrl, file, {
          onUploadProgress,
        });
      } catch (e) {
        console.log("putData: e=", e);
        props.onFailure();
        return
      }

      props.onSuccess();
    }

    e.preventDefault();
    callEndpoint();
  }

  if (!props.request) {
    return null
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Upload Outreach Video for {props.request.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            controlId="formFileInput"
            className="formGroup"
          >
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </Form.Group>
        </Form>
        <ProgressBar now={uploadProgress} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmitForm}
          disabled={(file === "")}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
