import React from 'react';
import Header from "../Header";
import {
  Container,
  ListGroup,
} from "react-bootstrap";

export default function AboutPage() {
  return (
    <div className="page">
      <Header />
      <Container>
        <h1 className="pageHeader">
          About
        </h1>
        <p className="pageTextLarge">
          Virtual Hugs was created in 2020, in the middle of the Covid-19 pandemic. It began with a Craig's List ad:
        </p>
        <blockquote>
          <p>
            Seems like everyone could use a hug and a pep talk these days. Message me, and I'll send you a virtual one. I'm just someone trying to help us get through this together. Be well.
          </p>
        </blockquote>
        <p className="pageTextLarge">
          The response was overwhelming. Since that first Craig's List post, hundreds of Virtual Hugs videos have been created and sent to people around the world.
        </p>
        <p className="pageTextLarge">
          Virtual Hugs has become a space of connection&#8212; connecting people in need of mental and emotional support, with the people who can provide it.
        </p>
        <p className="pageTextEmphasis">
          <b>Our vision is to make mental and emotional health care accessible and affordable to everyone.</b>
        </p>
        <h2>
          Team
        </h2>
        <div className="listGroupContainer">
          <ListGroup className="listGroup">
            <ListGroup.Item className="listGroupItem">
              <h5>
                Jed Lau
              </h5>
              <p>
                Founder
              </p>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup className="listGroupRow">
            <ListGroup.Item className="listGroupItem">
              <h5>
                Shanon Deyerle
              </h5>
              <p>
                Recruitment Coordinator
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItem">
              <h5>
                Charlie Tran
              </h5>
              <p>
                Software Engineering Intern
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItem">
              <h5>
                Brandon Torng
              </h5>
              <p>
                Business Development Intern
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItem">
              <h5>
                Eliam Mussie
              </h5>
              <p>
                Content Development Intern
              </p>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItem">
              <h5>
                Sarah Prosser
              </h5>
              <p>
                Content Development Intern
              </p>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Container>
    </div>
  )
}
