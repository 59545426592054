import React, {
  useState,
} from 'react';
import {
  Card,
} from 'react-bootstrap';
import VideoModal from '../unauthenticated/VideoModal';

export default function VideoCard(props) {
  const [showModal, setShowModal] = useState(false);

  const onTap = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  if (!props.video) {
    return null
  }

  return (
    <Card
      className="videoCard"
    >
      <VideoModal
        show={showModal}
        handleClose={handleClose}
        video={props.video}
      />
      <Card.Body className="videoCardBody">
        <Card.Title className="videoCardTitle">Click here to preview...</Card.Title>
      </Card.Body>
      <Card.Img className="videoCardImage" src={props.video.thumbnail_url} onClick={onTap} />
    </Card>
  )
}
