import React, {
  useState,
} from 'react';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import Header from "../Header";
import ProviderSignUpButton from "./ProviderSignUpButton";

const ACCESS_CODE = "HEARTSANDMINDS";

const AccessCodeModal = (props) => {
  const [code, setCode] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (code.toUpperCase() === ACCESS_CODE) {
      props.onAuthorized();
    }
  }

  return (
    <Modal
      show={!props.authorized}
      onHide={() => { }}
    >
      <Modal.Header>
        <Modal.Title>Enter Access Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => onSubmit(e)}
        >
          <Form.Group
            className="formGroup"
            controlId="formAccessCode"
          >
            <Form.Label>Joining Virtual Hugs as a provider is by invitation only at this time. If you were given an access code, please enter it here:</Form.Label>
            <Form.Control
              type="text"
              name="code"
              value={code}
              onInput={e => setCode(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default function ProviderSignUpPage() {
  const [authorized, setAuthorized] = useState(false);

  const onAuthorized = () => {
    setAuthorized(true);
  }

  return (
    <div className="page">
      <AccessCodeModal
        authorized={authorized}
        onAuthorized={onAuthorized}
      />
      <Header />
      <h1 className="pageHeader">
        Provider Sign Up
      </h1>
      <div className="signInContainer">
        <p>A Google account is required to sign up. Please sign into your Google account here:</p>
        <ProviderSignUpButton />
      </div>
    </div>
  )
}
