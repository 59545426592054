import React from 'react';
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import Header from "../../Header";
import AdminSignupsList from "./AdminSignupsList";
import AdminOutreachVideosList from "./AdminOutreachVideosList";
import AdminOutreachApprovedList from "./AdminOutreachApprovedList";

export default function AdminOutreachVideosPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Outreach
      </h1>
      <Tabs defaultActiveKey="signups" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="signups" title="Signups">
          <AdminSignupsList />
        </Tab>
        <Tab eventKey="requests" title="Requests">
          <AdminOutreachVideosList />
        </Tab>
        <Tab eventKey="approved" title="Approved">
          <AdminOutreachApprovedList />
        </Tab>
      </Tabs>
    </div>
  )
}
