import React from 'react';
import {
  Alert,
  Button,
} from "react-bootstrap";

export default function ResultAlert(props) {
  return (
    <Alert
      variant={props.variant}
      className="alert"
      show={props.show}
    >
      <div className="alertContainer">
        {props.message}
        <Button
          className="alertCloseButton"
          variant="outline"
          onClick={props.onDismiss}
        >
          &times;
        </Button>
      </div>
    </Alert>
  )
}
