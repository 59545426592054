import React from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from 'react-bootstrap';

import WhatWeDoPublicVideoSingleGallery from "./WhatWeDoPublicVideoSingleGallery";
import { ReactComponent as GuaranteeIcon } from '../../assets/money-back-guarantee.svg';
import { ReactComponent as ArrowIcon } from '../../assets/next.svg';

export default function WhatWeDoPanel(props) {
  const history = useHistory();

  return (
    <div className="whatWeDoPanel">
      <div className="getStartedContainer">
        <div className="getStartedStepsText">
          <h2>
            1. Watch our free public videos.
          </h2>
          <p>
            Free public videos help you find emotional support immediately.
          </p>
          <p>
            Browse our public videos here
            <ArrowIcon className="whatWeDoArrowIcon" />
          </p>
        </div>
        <div className="getStartedStepsGraphic">
          <WhatWeDoPublicVideoSingleGallery featuredVideos={props.featuredVideos} providers={props.providers}/>
        </div>
      </div>
      <div className="getStartedContainer">
        <div className="getStartedStepsText">
          <h2>
            2. Request a private video.
          </h2>
          <p>
            Get a personalized private video from a care provider of your choice. Private videos start at just $10 and are completely confidential.
          </p>
        </div>
        <div className="getStartedCTAContainer">
          <div className="getStartedCTAText">
            <p>
              To get started, create a free Virtual Hugs account.
            </p>
          </div>
          <div className="getStartedCTAButtonContainer">
            <Button
              onClick={() => history.push("/client/signup")}
            >
              Create account
            </Button>
          </div>
        </div>
      </div>
      <div className="getStartedContainer">
        <div className="getStartedStepsText">
          <h2>
            3. Book a live session.
          </h2>
          <p>
            Live sessions allow you to deepen your relationship with a care provider. This is the best option for those seeking a long-term solution.
          </p>
        </div>
        <div className="getStartedStepsGraphic">
          <p className="centeredColumn">
          </p>
        </div>
      </div>
    </div>
  )
}
