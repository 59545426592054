import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
} from "react-router-dom";
import {
  Button,
  Table,
} from 'react-bootstrap'
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import ProviderUploadOutreachVideoModal from './ProviderUploadOutreachVideoModal';
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ProviderOutreachVideosPage (props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requestsLastUpdatedAtMs, setRequestsLastUpdatedAtMs] = useState(Date.now());
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState(false);
  const [showUploadFailureAlert, setShowUploadFailureAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/outreach"].join("/"), {
        headers,
      });
      setRequests(res.data.requests);
    }

    fetchData();
  }, [requestsLastUpdatedAtMs]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderActions = (request) => {
    const onClickUpload = (request) => {
      setSelectedRequest(request);
      setShowUploadModal(true);
    }

    switch (request.status) {
      case "ASSIGNED":
        return (
          <Button
            className="button-link"
            onClick={() => onClickUpload(request)}
          >
            upload video
          </Button>
        )
      case "SUBMITTED":
        return (
          <div className="column">
            {/* <Link
              to={["/provider/outreach", request.id, "preview"].join("/")}
              target="_blank"
              rel="noopener noreferrer"
            >
              preview
            </Link> */}
            <Button
              className="button-link"
              onClick={() => onClickUpload(request)}
            >
              replace video
            </Button>
          </div>
        )
      default:
        return null
    }
  }

  const renderRow = (i) => {
    const request = requests[i];
    return (
      <tr key={i}>
        <td>{i+1}</td>
        <td key={"name-" + i}>
          {request.name}
        </td>
        <td key={"campaign-" + i}>
          {request.campaign}
        </td>
        <td key={"context-" + i}>
          {request.context}
        </td>
        <td key={"status-" + i}>
          {request.status}
        </td>
        <td key={"actions-" + i}>
          {renderActions(request)}
        </td>
      </tr>
    )
  }

  const onUploadSuccess = () => {
    setShowUploadModal(false);
    setShowUploadSuccessAlert(true);

    // Update the timestamp, so that the API endpoint to refresh the list of requests is called.
    setRequestsLastUpdatedAtMs(Date.now());
  }

  const onUploadFailure = () => {
    setShowUploadModal(false);
    setShowUploadFailureAlert(true);
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Outreach Videos
      </h1>
      <ResultAlert
        show={showUploadSuccessAlert}
        variant="success"
        message="The video was uploaded successfully."
        onDismiss={() => setShowUploadSuccessAlert(false)}
      />
      <ResultAlert
        show={showUploadFailureAlert}
        variant="danger"
        message="The video did not upload successfully. Please try again."
        onDismiss={() => setShowUploadFailureAlert(false)}
      />
      <div
        style={{
          backgroundColor: "lightpink",
        }}
      >
        Guidelines for flyer videos:
          <ul>
            <li>Intro: "Hi, I'm [name], a counselor from Virtual Hugs."</li>
            <li>1-2 minutes long</li>
            <li>Ending: "If you found this helpful, I invite you to join the Virtual Hugs community by clicking the button below. Signing up is free, and on top of that, you'll receive a complimentary one-on-one video, recorded just for you by a certified counselor like me. So sign up now, and start getting personalized support today."</li>
          </ul>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th key="nameFirst">First name</th>
            <th key="campaign">Campaign</th>
            <th key="context">Context</th>
            <th key="status">Status</th>
            <th key="actions">Action(s)</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: requests.length }).map((_, index) => renderRow(index))}
        </tbody>
      </Table>
      <ProviderUploadOutreachVideoModal
        show={showUploadModal}
        request={selectedRequest}
        onCancel={() => setShowUploadModal(false)}
        onSuccess={onUploadSuccess}
        onFailure={onUploadFailure}
      />
    </div>
  )
}
