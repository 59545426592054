import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";
import {
  useAuth,
} from "./shared";

export default function AdminSignInButton() {
  let history = useHistory();
  let auth = useAuth();
  let location = useLocation();
  let from = {
    pathname: location.pathname || "/",
  }

  let logout = async (e) => {
    e.preventDefault();
    try {
      await auth.adminSignOut()
    } catch (e) {
      console.log("logout error, e=", e);
      return
    }

    history.push("/");
  }

  let login = async (e) => {
    e.preventDefault();
    try {
      await auth.adminSignIn();
    } catch (e) {
      console.log("login error, e=", e);
      return
    }

    history.replace(from);
    history.push("/admin/dashboard");
  }

  if (auth.isAdminSignedIn) {
    return (
      <div>
        Admin is already signed in.
        <ul>
          <li>
            <Button
              className="button-link"
              onClick={logout}
            >
              Sign Out
            </Button>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <Button
      className="button-link"
      onClick={login}
    >
      Sign in with Google
    </Button>
  )
}
