import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Alert,
  Button,
  ListGroup,
} from "react-bootstrap";
import Header from "../../Header";
import {
  useAuth,
} from "../../auth/shared";
import ProviderNewPublicVideoFormModal from './ProviderNewPublicVideoFormModal';
import VideoTableCell from "../VideoTableCell";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ProviderPublicVideosPage() {
  const history = useHistory();
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [publicVideos, setPublicVideos] = useState([]);
  const [lastUpdatedPublicVideosAtMs, setLastUpdatedPublicVideosAtMs] = useState(Date.now());
  const [showNewPublicVideoFormModal, setShowNewPublicVideoFormModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [showCancelUploadAlert, setShowCancelUploadAlert] = useState(false);

  // API

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/videos/public"].join("/"), {
        headers,
      });
      setPublicVideos(res.data.data.videos);
    }

    fetchData();
  }, [lastUpdatedPublicVideosAtMs]);  // eslint-disable-line react-hooks/exhaustive-deps

  // table

  const onVideoCellClick = (video) => {
    history.push({
      pathname: "/provider/video/" + video.id,
      video,
    });
  }

  const renderTable = () => {
    return (
      <ListGroup as="ul">
        {publicVideos.map(video =>
          <VideoTableCell
            key={video.id}
            video={video}
            onClick={onVideoCellClick}
          />
        )}
      </ListGroup>
    )
  }

  const onSuccess = () => {
    setShowNewPublicVideoFormModal(false);
    setShowSuccessAlert(true);

    // Update the timestamp, so that the API endpoint to refresh the list of videos is called.
    setLastUpdatedPublicVideosAtMs(Date.now());
  }

  const onFailure = () => {
    setShowNewPublicVideoFormModal(false);
    setShowFailureAlert(true);
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Public Videos
      </h1>
      <Button
        className="providerUploadButton"
        onClick={() => setShowNewPublicVideoFormModal(true)}
      >
        + Upload New Video
      </Button>
      <ResultAlert
        show={showSuccessAlert}
        variant="success"
        message="The video was uploaded successfully."
        onDismiss={() => setShowSuccessAlert(false)}
      />
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="The video did not upload successfully. Please try again."
        onDismiss={() => setShowFailureAlert(false)}
      />
      <ResultAlert
        show={showCancelUploadAlert}
        variant="danger"
        message="The video upload was cancelled."
        onDismiss={() => setShowCancelUploadAlert(false)}
      />
      {renderTable()}
      <ProviderNewPublicVideoFormModal
        show={showNewPublicVideoFormModal}
        onCancel={() => setShowNewPublicVideoFormModal(false)}
        onCancelVideoUpload={() => setShowCancelUploadAlert(true)}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    </div>
  )
}
