import React from "react";
import {
  Button,
  Modal,
} from 'react-bootstrap';

export default function SignInErrorModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={() => { }}
    >
      <Modal.Header>
        <Modal.Title>Sign-In Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        An error occurred. Please ensure that you are signing in with the correct credentials. If you don't have an account, please <a href={props.signUpLink}>sign up</a>.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => props.dismissModal()}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
