import React from 'react';
import Header from "../Header";
import AdminSignInButton from "../auth/AdminSignInButton";

export default function AdminSignInPage() {
  return (
    <div className="page">
      <Header
        hideMenu
      />
      <h1>Admin Sign In</h1>
      <ul>
        <li><AdminSignInButton /></li>
      </ul>
    </div>
  )
}
