import React from 'react';
import Header from "../../Header";
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";

export default function ClientPaymentSuccessPage() {
  const history = useHistory();

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Your payment was successful. You're all set!
      </h1>
      <div className="nextStepsContainer">
        <div className="nextStepContainer">
          <div className="nextStepText">
            <h4 className="titleMarginBottom">
              You'll receive an email when your private Virtual Hugs video is ready.
              In the meantime, check out some of our public videos to start getting support now.
            </h4>
          </div>
          <div className="centeredColumn">
            <Button
              onClick={() => history.push("/public/gallery")}
            >
              Go to public videos
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
