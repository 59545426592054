import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
} from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownButton,
  Table,
} from 'react-bootstrap'
import {
  useAuth,
} from "../../auth/shared";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function AdminSignupsList (props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [signups, setSignups] = useState([]);
  const [lastUpdatedAtMsSignups, setLastUpdatedAtMsSignups] = useState(Date.now());

  useEffect(() => {
    const fetchData = async () => {
      // Get signups.
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/signups/uninvited"].join("/"), {
        headers,
      });
      setSignups(res.data);
    }

    fetchData();
  }, [lastUpdatedAtMsSignups]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderActions = (signup) => {
    const onClickRemove = (signup) => {
      const callRemoveEndpoint = async () => {
        await axios.delete([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/signup", signup.id].join("/"), {
          headers,
        });
        setLastUpdatedAtMsSignups(Date.now());
      }

      callRemoveEndpoint(signup);
    }

    const onClickEmailInvite = (signup) => {
      const callEmailInviteEndpoint = async () => {
        await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/signup", signup.id, "invite"].join("/"), {}, {
          headers,
        });
        setLastUpdatedAtMsSignups(Date.now());
      }

      callEmailInviteEndpoint(signup);
    }

    return (
      <div className="column">
        <Button
          className="button-link"
          onClick={() => onClickRemove(signup)}
        >
          remove
        </Button>
        <Button
          className="button-link"
          onClick={() => onClickEmailInvite(signup)}
        >
          email invitation
        </Button>
      </div>
    )
  }

  const renderRow = (i) => {
    const signup = signups[i];
    return (
      <tr key={i}>
        <td>{i+1}</td>
        <td key={"nameFirst-" + i}>
          {signup.nameFirst}
        </td>
        <td key={"email-" + i}>
          {signup.email}
        </td>
        <td key={"campaign-" + i}>
          {signup.campaign}
        </td>
        <td key={"actions-" + i}>
          {renderActions(signup)}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th key="nameFirst">First name</th>
            <th key="campaign">Campaign</th>
            <th key="actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: signups.length }).map((_, index) => renderRow(index))}
        </tbody>
      </Table>
    </div>
  )
}
