import React, {
  useState,
} from 'react';
import {
  Link,
} from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownButton,
  ListGroup,
  Modal,
} from 'react-bootstrap';

export default function AdminPrivateVideoActionsModal(props) {

  const [providerID, setProviderID] = useState(null);
  const [action, setAction] = useState(null);

  const renderProvidersList = () => {
    const providers = [];

    props.providers.map(provider => {
      providers.unshift(
        <Dropdown.Item
          id={provider.id}
          key={"dropdownitem-" + provider.id}
          active={(provider.id === providerID)}
          onClick={e => {
            e.preventDefault();
            setAction("assign");
            setProviderID(e.currentTarget.id)
          }}
        >
          {provider.profile.nameFirst + " " + provider.profile.nameLast}
        </Dropdown.Item>
      )
    })

    return (
      <DropdownButton id="dropdown-basic-button" title="assign">
        {providers}
      </DropdownButton>
    )
  }

  const renderActions = () => {
    const actions = [
      <ListGroup.Item action
        key={"listgroupitem-remove"}
        onClick={() => setAction("remove")}
      >
        remove
      </ListGroup.Item>
    ];

    switch (props.req.status) {
      case "CREATED":
        actions.unshift(
          <ListGroup.Item
            key={"listgroupitem-providers"}
          >
            {renderProvidersList()}
          </ListGroup.Item>
        );
        break
      case "PROCESSED":
      case "SUBMITTED":
        actions.unshift(
          <ListGroup.Item action
            key={"listgroupitem-reject"}
            onClick={() => setAction("reject")}
          >
            reject
          </ListGroup.Item>
        );
        actions.unshift(
          <ListGroup.Item action
            key={"listgroupitem-approve"}
            onClick={() => setAction("deliver")}
          >
            approve/deliver
          </ListGroup.Item>
        );
        actions.unshift(
          <ListGroup.Item action
            key={"listgroupitem-preview"}
          >
            <Link
              to={["/admin/private", props.req.id + "?preview=true"].join("/")}
              target="_blank"
              rel="noopener noreferrer"
            >
              preview
            </Link>
          </ListGroup.Item>
        );
        break
      default:
        break
    }

    return (
      <ListGroup>
        {actions}
      </ListGroup>
    )
  }

  const resetModal = () => {
    setProviderID(null);
    setAction(null);
  }

  const onCancel = () => {
    props.onCancel();
    resetModal();
  }

  const onSubmit = () => {
    switch (action) {
      case "assign":
        props.onAssign(providerID);
        break
      case "deliver":
        props.onDeliver();
        break
      case "reject":
        props.onReject();
        break
      case "remove":
        props.onRemove();
        break
      default:
        break
    }

    resetModal();
  }

  if (!props.req) {
    return null
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Private Video Request Actions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        req: {props.req.id}
        {renderActions()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmit}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
