import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Button,
  Col,
  Form,
} from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import VideoPreviewCard from "../VideoPreviewCard";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "../../SuccessModal";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ProviderVideoSettingsPage() {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { videoID } = useParams();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [videoOrig, setVideoOrig] = useState(null);
  const [video, setVideo] = useState(null);
  const [showRemoveVideoConfirmationModal, setShowRemoveVideoConfirmationModal] = useState(false);
  const [showRemoveVideoSuccessModal, setShowRemoveVideoSuccessModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      const res = await axios.get([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/video", videoID].join("/"), {
        headers,
      });
      setVideoOrig(res.data.video);
      setVideo(res.data.video);
    }

    const myVideo = location.video || (location.state && location.state.video);
    if (myVideo) {
      setVideoOrig(myVideo);
      setVideo(myVideo);
    } else {
      // The video isn't in location. (This could be because the video settings page was reloaded. Make an API call to fetch the video.)
      fetchVideo();
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const updateVideo = async () => {
    const data = {
      video,
    };

    try {
      await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/video", videoID].join("/"),
        data, {
          headers,
        }
      );
    } catch (e) {
      setShowFailureAlert(true);
      return
    }
    setShowSuccessAlert(true);
    setVideoOrig(video);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    updateVideo();
  }

  const onInputTitle = (e) => {
    const myVideo = JSON.parse(JSON.stringify(video));
    myVideo.title = e.target.value;
    setVideo(myVideo);
  }

  const onInputDescription = (e) => {
    const myVideo = JSON.parse(JSON.stringify(video));
    myVideo.description = e.target.value;
    setVideo(myVideo);
  }

  const onClickRemoveVideo = () => {
    setShowRemoveVideoConfirmationModal(true);
  }

  const onConfirmRemoveVideo = () => {
    const deleteVideo = async () => {
      try {
        await axios.delete([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/video", videoID].join("/"), {
          headers,
        });
      } catch (e) {
        setShowFailureAlert(true);
        return
      }

      setShowRemoveVideoSuccessModal(true);
    }

    setShowRemoveVideoConfirmationModal(false);
    deleteVideo();
  }

  const onDismissRemoveVideoSuccessModal = () => {
    history.replace("/provider/dashboard");
  }

  if (!video) {
    return null
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Video Settings
      </h1>
      <h4 className="subheader">
        Changes need to be approved by an administrator before the video can be displayed again.
      </h4>
      <div className="centeredColumn">
        <VideoPreviewCard
          video={video}
        />
      </div>
      <Form
        className="form"
        onSubmit={e => onSubmit(e)}
      >
        <Form.Group
          controlId="formTitle"
          className="formGroup"
        >
          <Form.Label>Update title</Form.Label>
          <Col sm={5}>
          <TextareaAutosize
            className="formTextarea"
            name="title"
            placeholder="Update video title"
            value={video.title}
            onInput={onInputTitle}
            required
          />
          </Col>
        </Form.Group>
        <Form.Group
          controlId="formDescription"
          className="formGroup"
        >
          <Form.Label>Update description</Form.Label>
          <Col sm={5}>
          <TextareaAutosize
            className="formTextarea"
            name="description"
            placeholder="Update video description"
            value={video.description}
            onInput={onInputDescription}
            required
          />
          </Col>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={((videoOrig.title === video.title) && (videoOrig.description === video.description))}
        >
          Update
        </Button>
      </Form>
      <Button
        variant="danger"
        onClick={onClickRemoveVideo}
      >
        Remove video
      </Button>
      <ConfirmationModal
        show={showRemoveVideoConfirmationModal}
        titleText="Are you sure?"
        bodyText="Removing this video will remove it from display on the Virtual Hugs website."
        cancelText="Cancel"
        confirmText="Yes, remove video."
        onCancel={() => setShowRemoveVideoConfirmationModal(false)}
        onConfirm={() => onConfirmRemoveVideo()}
      />
      <SuccessModal
        show={showRemoveVideoSuccessModal}
        titleText="Video Removed"
        onDismiss={onDismissRemoveVideoSuccessModal}
      />
      <ResultAlert
        show={showSuccessAlert}
        variant="success"
        message="Video settings were updated successfully."
        onDismiss={() => setShowSuccessAlert(false)}
      />
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="Something went wrong. Please try again later."
        onDismiss={() => setShowFailureAlert(false)}
      />
    </div>
  )
}