import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {
  Button,
  Container,
} from 'react-bootstrap';
import {
  useAuth,
} from "../../auth/shared";
import {
  useQuery,
} from "../../../utils/query";
import Footer from "../../Footer";
import Header from "../../Header";
import ProviderProfileCard from '../../unauthenticated/ProviderProfileCard';

const axios = require('axios').default;

export default function ClientPrivateVideoPage() {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }
  const history = useHistory();
  const query = useQuery();

  const [request, setRequest] = useState(null);
  const [client, setClient] = useState(null);
  const [provider, setProvider] = useState(null);

  const {
    reqID,
  } = useParams();
  const preview = query.get("preview");

  useEffect(() => {
    const callEndpoint = async () => {
      let res = null;
      if (preview) {
        res = await axios.get([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/private", reqID].join("/"), {
          headers,
        });
      } else {
        res = await axios.post([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/private", reqID, "view"].join("/"), {}, {
          headers,
        });
      }
      setRequest(res.data.request);
      setClient(res.data.client);
      setProvider(res.data.provider);
    }

    callEndpoint();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickMessage = () => {
    history.push({
      pathname: '/client/messages',
      provider: {
        id: provider.id,
        username: provider.username,
      },
    })
  }

  if (!request || !client || !provider) {
    return null
  }

  return (
    <div className="pageWithFooter">
      <Header />
      <h1 className="pageHeader centered">
        A video for {client.profile.nameFirst}
      </h1>
      <Container className="centeredColumn">
        <video
          className="modalVideo"
          controls
          controlsList="nodownload"
          autoPlay
          muted
          preload="metadata"
        >
          <source
            src={request.video_url}
            type="video/mp4"
          />
        </video>
        <div className="spacerVertical" />
      </Container>
      <div className="belowVideoContainer">
        <div className="providerProfileAndLikeContainer">
          <div className="providerProfileContainer">
            Recorded (just for you!) by the following Virtual Hugs provider:
            <ProviderProfileCard
              providerProfile={provider.profile}
            />
            <div className="providerProfileMessageButtonContainer">
              <Button className="messageButton" size="lg" onClick={onClickMessage}>
                Message {provider.profile.nameFirst}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
