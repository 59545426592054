import React from "react";
import {
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";
import {
  useAuth,
} from "./shared";
import {
  LS_KEY_AUTH_TRIGGER,
} from "../../utils/globals";

export default function PrivateClientRoute({ children, ...rest }) {
  const auth = useAuth();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        if (!auth.initialized) {
          return (
            <h1>
              Loading...
            </h1>
          )
        }

        if (!auth.isClientSignedIn) {
          localStorage.setItem(LS_KEY_AUTH_TRIGGER, JSON.stringify(location));
          return (
            <Redirect
              to={{
                pathname: "/client/signin",
                state: location,
              }}
            />
          )
        }

        return (children)
      }}
    />
  );
}