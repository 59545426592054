import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  useAuth,
} from "../../auth/shared";
import AdminPrivateVideoActionsModal from './AdminPrivateVideoActionsModal';

const axios = require('axios').default;

export default function AdminPrivateVideoRequestsList (props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [requests, setRequests] = useState([]);
  const [providers, setProviders] = useState([]);
  const [requestsLastUpdatedAtMs, setRequestsLastUpdatedAtMs] = useState(Date.now());
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData");
      // Get providers.
      let res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/users/providers"].join("/"), {
        headers,
      });
      setProviders(res.data);
      const myProviders = res.data;

      // Get requests.
      res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private"].join("/"), {
        headers,
      });

      const clients = res.data.clients;
      for (let i = 0; i < res.data.requests.length; i++) {
        const req = res.data.requests[i];

        // Get client's name, if possible.
        if (clients[req.client_id]) {
          req.client_name_first = clients[req.client_id].nameFirst;
        }

        // Get provider's name, if assigned.
        if (req.provider_id === "(unassigned)") {
          req.provider_name = "(unassigned)";
        } else {
          const index = myProviders.findIndex(provider => provider.id === req.provider_id);
          if (index !== -1) {
            const provider = myProviders[index];
            req.provider_name = [provider.profile.nameFirst, provider.profile.nameLast].join(" ");
          }
        }
      }
      setRequests(res.data.requests);
    }

    fetchData();
  }, [requestsLastUpdatedAtMs]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * AgGrid
   */
  const defaultColDef = useMemo( ()=> ({
    sortable: true
  }));

  const createdValueGetter = (params) => {
    return new Date(parseInt(params.data.created_at_ms)).toLocaleString()
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Name",
      field: "client_name_first",
    },
    {
      headerName: "Created",
      valueGetter: createdValueGetter,
    },
    {
      headerName: "Context1",
      field: "context1",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Context2",
      field: "context2",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Provider",
      field: "provider_name",
    },
  ]);

  const cellClickedListener = useCallback( event => {
    setSelectedRequest(event.data);
    setShowAssignModal(true);
  }, []);

  const onAssign = async (providerID) => {
    try {
      await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private", selectedRequest.id, "assign"].join("/"), {
        providerID,
      }, {
        headers,
      });
    } catch (e) {
      console.log("onAssign: e=", e);
      return
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowAssignModal(false);
  }

  const onDeliver = async () => {
    try {
      await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private", selectedRequest.id, "deliver"].join("/"), {}, {
        headers,
      });
    } catch (e) {
      console.log("onDeliver: e=", e);
      return
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowAssignModal(false);
  }

  const onReject = async () => {
    try {
      await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private", selectedRequest.id, "reject"].join("/"), {}, {
        headers,
      });
    } catch (e) {
      console.log("onReject: e=", e);
      return
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowAssignModal(false);
  }

  const onRemove = async () => {
    try {
      await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/private", selectedRequest.id, "remove"].join("/"), {}, {
        headers,
      });
    } catch (e) {
      console.log("onRemove: e=", e);
      return
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowAssignModal(false);
  }

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{height: 400, width: "100%"}}
      >
        <AgGridReact
          rowData={requests}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          onCellClicked={cellClickedListener}
        />
      </div>
      <AdminPrivateVideoActionsModal
        show={showAssignModal}
        req={selectedRequest}
        providers={providers}
        onCancel={() => setShowAssignModal(false)}
        onAssign={onAssign}
        onDeliver={onDeliver}
        onReject={onReject}
        onRemove={onRemove}
      />
    </div>
  )
}
