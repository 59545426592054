import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import {
  useHistory,
} from "react-router-dom";

export default function GoToPublicVideosPanel() {
  let history = useHistory();

  return (
    <div className="goToPublicVideosPanel">
      <h1>
        Take the first step.
      </h1>
      <h3>
        Watch public videos from our care providers, and start receiving emotional support now.
      </h3>
      <div className="goToPublicVideosButtonContainer">
        <Button
          size="lg"
          onClick={() => history.push("/public/gallery") }
        >
          Go to public video gallery
        </Button>
      </div>
    </div>
  )
}
