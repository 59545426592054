import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
  Form,
} from 'react-bootstrap';
import Header from "../../Header";
import {
  useAuth,
} from "../../auth/shared";

const axios = require('axios').default;

export default function ProviderSignUpCreateProfilePage() {
  const auth = useAuth();
  const history = useHistory();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [bioShort, setBioShort] = useState("");

  const updateProfile = async () => {
    const data = {
      bioShort,
    };

    await axios.post([process.env.REACT_APP_API_PROVIDER_ENDPOINT, "provider/settings"].join("/"),
      data, {
        headers,
      }
    );
  }

  const onSubmit = (e) => {
    e.preventDefault();

    updateProfile();

    history.replace("/provider/signup/success");
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Last Step!
      </h1>
      <Form
        className="signUpForm"
        onSubmit={e => onSubmit(e)}
      >
        <Form.Group
          controlId="formBioShort"
          className="formGroup"
        >
          <Form.Label>Write a short bio about yourself (anywhere from a few sentences to 1-2 paragraphs... you can edit this later): </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="bioShort"
            placeholder="Bio"
            value={bioShort}
            onInput={e => setBioShort(e.target.value)}
            onChange={() => { }}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
        >
          Submit
          </Button>
      </Form>
    </div>
  )
}
