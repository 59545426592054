import React from 'react';
import Header from "../Header";
import ProviderSignInButton from "../auth/ProviderSignInButton";

export default function ProviderSignInPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Provider Sign In
      </h1>
      <div className="signInContainer">
        <ProviderSignInButton />
      </div>
    </div>
  )
}
