import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  ListGroup,
} from "react-bootstrap";
import {
  useAuth,
} from "../../auth/shared";
import Header from "../../Header";
import PrivateVideoTableCell from "../PrivateVideoTableCell";

const axios = require('axios').default;

export default function ClientDashboardPage (props) {
  const history = useHistory();
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [videos, setVideos] = useState([]);
  const [providers, setProviders] = useState({});
  const [videosLastUpdatedAtMs, setVideosLastUpdatedAtMs] = useState(Date.now());

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/private"].join("/"), {
        headers,
      });
      setVideos(res.data.videos);
      setProviders(res.data.providers);
    }

    fetchData();
  }, [videosLastUpdatedAtMs]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderTable = () => {
    return (
      <ListGroup as="ul">
        {videos.map(video =>
          <PrivateVideoTableCell
            key={video.id}
            video={video}
            provider={providers[video.provider_id]}
            onClick={() => history.push(["/client/private", video.id].join("/"))}
          />
        )}
      </ListGroup>
    )
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Private Videos
      </h1>
      {renderTable()}
    </div>
  )
}
