import React, {
  useEffect,
  useState,
} from 'react';
import Header from "../../Header";
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from "react-bootstrap";
import {
  useAuth,
} from "../../auth/shared";
import ClientSignUpPrivateVideoRequestForm from "./ClientSignUpPrivateVideoRequestForm";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ClientSignUpPrivateVideoRequestPage() {
  const auth = useAuth();
  const history = useHistory();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [showFailureAlert, setShowFailureAlert] = useState(false);

  const onSubmitForm = (context1, context2) => {
    const callEndpoint = async () => {
      const data = {
        context1,
        context2,
      };

      try {
        await axios.post([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/private"].join("/"),
          data, {
            headers,
          }
        );
      } catch (e) {
        setShowFailureAlert(true);
        return
      }

      history.replace("/client/signup/success");
    }

    callEndpoint();
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Congratulations! You've created your Virtual Hugs account.
      </h1>
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="Hmm... that didn't work. Count to 3 and try again?"
        onDismiss={() => setShowFailureAlert(false)}
      />
      <div className="nextStepsContainer">
        <div className="nextStep1Container">
          <div className="nextStepText">
            <h3 className="centered titleMarginBottom">
              Now, let's get you that free private video.
            </h3>
            <p>
              Tell us what's on your mind, and one of our Virtual Hugs coaches will record a video, just for you.
            </p>
            <ClientSignUpPrivateVideoRequestForm
              onSubmit={onSubmitForm}
            />
          </div>
        </div>
        <div className="nextStep2Container">
          <div className="nextStepText">
            <h3 className="centered titleMarginBottom">
              Not ready for a private video yet?
            </h3>
            <p>
              No problem. Head to our free public videos, and start getting emotional support now.
            </p>
          </div>
          <div className="centeredColumn">
            <Button
              variant="outline-primary"
              onClick={() => history.replace("/public/gallery")}
            >
              Go to public videos
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
