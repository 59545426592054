import React from "react";
import {
  useHistory,
} from "react-router-dom";
import {
  Nav,
} from 'react-bootstrap';
import {
  useAuth,
} from "./shared";

export default function ClientAuthHeader() {
  const history = useHistory();
  const auth = useAuth();

  const signout = async () => {
    try {
      await auth.clientSignOut()
    } catch (e) {
      console.log("logout error, e=", e);
      return
    }

    history.push("/");
  }

  const onSelect = async (selectedKey) => {
    switch (selectedKey) {
      case "signout":
        await signout();
        break
      default:
        break
    }
  }

  return (
    <Nav
      className="navContainer"
      onSelect={onSelect}
    >
      <Nav.Item>
        <Nav.Link
          eventKey="dashboard"
          href="/client/dashboard"
        >
          Private Videos
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="messages"
          href="/client/messages"
        >
          Messages
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="signout"
        >
          Log out
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}