import React, {
  useState,
} from 'react';
import {
  Button,
  Form,
} from "react-bootstrap";

export default function ClientPrivateVideoRequestForm(props) {
  const [context1, setContext1] = useState("");
  const [context2, setContext2] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(context1, context2);
  }

  return (
    <Form
      className="form"
      onSubmit={onSubmit}
    >
      <Form.Group
        controlId="formContext1"
        className="formGroup"
      >
        <Form.Label>What do you want {props.providerNameFirst} to talk about?</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={context1}
          onInput={e => setContext1(e.target.value)}
        />
      </Form.Group>
      <Form.Group
        controlId="formContext2"
        className="formGroup"
      >
        <Form.Label>Is there anything else {props.providerNameFirst} should know?</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={context2}
          onInput={e => setContext2(e.target.value)}
        />
      </Form.Group>
      <div>
        <p>
          A private video from {props.providerNameFirst} costs ${props.priceCents / 100} (plus taxes and fees).
        </p>
        <div className="multipleButtonsContainer">
          <Button
            className="buttonContainer"
            variant="primary"
            type="submit"
            disabled={((context1.length === 0) && (context2.length === 0))}
          >
            Go to payment
          </Button>
          <Button
            className="buttonContainer"
            variant="secondary"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  )
}
