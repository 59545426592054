import React from 'react';
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import Header from "../../Header";
import AdminPrivateVideoRequestsList from "./AdminPrivateVideoRequestsList";

export default function AdminPrivateVideosPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Private Videos
      </h1>
      <Tabs defaultActiveKey="privateVideoRequests" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="privateVideoRequests" title="Private Video Requests">
          <AdminPrivateVideoRequestsList />
        </Tab>
      </Tabs>
    </div>
  )
}
