import React from 'react';
import {
  ListGroup,
} from "react-bootstrap";
import {
  msToTime,
} from "../../utils/dateTime";
import ProviderProfileCard from '../unauthenticated/ProviderProfileCard';

export default function PrivateVideoTableCell(props) {
  const video = props.video;
  const provider = props.provider;

  if (!video || ! provider) {
    return null
  }

  return (
    <ListGroup.Item
      key={video.id}
      action
      onClick={() => props.onClick(video)}
    >
      <div className="videoTableCellContainer">
        <div className="videoTableVideoContainer">
          <div
            className="videoTableThumbnailImage"
            style={{
              backgroundImage: `url(` + video.thumbnail_url + `)`,
            }}
          />
          <div className="videoTableTextContainer">
            <div className="videoTableTitle">
              {video.title}
            </div>
            <div className="videoTableDescription">
              {video.description}
            </div>
            <div className="videoTableDateContainer">
              <div className="videoTableDate">
                {msToTime(video.delivered_at_ms)}
              </div>
            </div>
          </div>
        </div>
        <div className="videoTableStatusContainer">
          <ProviderProfileCard
            providerProfile={provider.profile}
          />
        </div>
      </div>
    </ListGroup.Item>
  )
}
