import React from 'react';
import Header from "../../Header";

export default function AdminDashboardPage() {
  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Admin Dashboard
      </h1>
      <ul>
      <li>
          <a href="/admin/outreach">Outreach</a>
        </li>
        <li>
          <a href="/admin/users">Users</a>
        </li>
        <li>
          <a href="/admin/public">Public Videos</a>
        </li>
        <li>
          <a href="/admin/private">Private Videos</a>
        </li>
      </ul>
    </div>
  )
}
