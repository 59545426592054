import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  useHistory,
} from "react-router-dom";
import {
  Alert,
  Button,
  Table,
} from "react-bootstrap";
import {
  useAuth,
} from "../../auth/shared";
import ConfirmationModal from '../ConfirmationModal';

const axios = require('axios').default;

export default function AdminOutreachApprovedList() {
  const history = useHistory();
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [providers, setProviders] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [requestsLastUpdatedAtMs, setRequestsLastUpdatedAtMs] = useState(Date.now());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [reqToRemove, setReqToRemove] = useState(null);

  // API

  useEffect(() => {
    const fetchData = async () => {
      // Get providers.
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/users/providers"].join("/"), {
        headers,
      });
      setProviders(res.data);
    }

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      // Get approved requests.
      const res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach/approved"].join("/"), {
        headers,
      });
      setApprovedRequests(res.data.requests);
    }

    fetchData();
  }, [requestsLastUpdatedAtMs]); // eslint-disable-line react-hooks/exhaustive-deps

  // table

  const renderAssignee = (req) => {

    let currentValue = req.provider_id;
    if (currentValue !== "(unassigned)") {
      const index = providers.findIndex(provider => provider.id === req.provider_id);
      if (index !== -1) {
        const provider = providers[index];
        currentValue = [provider.profile.nameFirst, provider.profile.nameLast].join(" ");
      }
    }

    return (currentValue)
  }

  const onConfirmRemove = async () => {
    try {
      await axios.delete([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach", reqToRemove].join("/"), {
        headers,
      });
    } catch (e) {
      console.log("onConfirmRemove: e=", e);
    }

    setRequestsLastUpdatedAtMs(Date.now());
    setShowConfirmationModal(false);
    setReqToRemove(null);
  }

  const renderActions = (req) => {
    const onClickRemove = async () => {
      setShowConfirmationModal(true);
      setReqToRemove(req.id);
    }

    const actions = [
      (
        <Button
          key={"remove-" + req.id}
          className="button-link"
          onClick={() => onClickRemove(req.id)}
        >
          remove
        </Button>
      ),
    ];

    actions.unshift(
      <Link
        key={"preview-" + req.id}
        to={["/outreach", req.id + "?preview=true"].join("/")}
        target="_blank"
        rel="noopener noreferrer"
      >
        preview
      </Link>
    );

    return (
      <div className="column">
        {actions}
      </div>
    )
  }

  const renderRow = (i) => {
    const req = approvedRequests[i];
    return (
      <tr key={i}>
        <td>{i+1}</td>
        <td key={"recipientName-" + i}>
          {req.name}
        </td>
        <td key={"context-" + i}>
          {req.context}
        </td>
        <td key={"assignedto-" + i}>
          {renderAssignee(req)}
        </td>
        <td key={"numViews-" + i}>
          {req.num_views}
        </td>
        <td key={"numCTAClicks-" + i}>
          {req.num_cta_clicks}
        </td>
        <td key={"numAccountsCreated-" + i}>
          {req.num_accounts_created}
        </td>
        <td key={"actions-" + i}>
          {renderActions(req)}
        </td>
      </tr>
    )
  }

  const renderTable = () => {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th key="recipientName">Recipient's name</th>
            <th key="context">Context</th>
            <th key="assignedTo">Assigned to</th>
            <th key="numViews"># views</th>
            <th key="numCTAClicks"># CTA clicks</th>
            <th key="numAccountsCreated"># accounts created</th>
            <th key="actions">Action(s)</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: approvedRequests.length }).map((_, index) => renderRow(index))}
        </tbody>
      </Table>
    )
  }

  return (
    <div>
      {renderTable()}
      <ConfirmationModal
        show={showConfirmationModal}
        titleText="Are you sure?"
        bodyText="This will set the outreach video request to REMOVED."
        cancelText="Cancel"
        confirmText="Yes"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => onConfirmRemove()}
      />
    </div>
  )
}
