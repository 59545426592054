import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

// Public pages
import HomePage from "./components/unauthenticated/HomePage";
import AboutPage from "./components/unauthenticated/AboutPage";
import PublicGalleryPage from "./components/unauthenticated/PublicGalleryPage";
import PrivacyPage from "./components/unauthenticated/PrivacyPage";
import TermsConditionsPage from "./components/unauthenticated/TermsConditionsPage";
import ProviderProfilePage from "./components/unauthenticated/ProviderProfilePage";
import SocialInvitePage from "./components/unauthenticated/SocialInvitePage";
import UnsubscribePage from "./components/unauthenticated/UnsubscribePage";
import OutreachVideoPage from "./components/unauthenticated/OutreachVideoPage";

// Auth
import {
  ProvideAuth,
} from "./components/auth/shared";
import PrivateClientRoute from "./components/auth/PrivateClientRoute";
import PrivateProviderRoute from "./components/auth/PrivateProviderRoute";
import PrivateAdminRoute from "./components/auth/PrivateAdminRoute";
import ClientSignUpPage from "./components/auth/ClientSignUpPage";
import ClientSignInPage from "./components/auth/ClientSignInPage";
import ProviderSignUpPage from "./components/auth/ProviderSignUpPage";
import ProviderSignInPage from "./components/auth/ProviderSignInPage";
import AdminSignUpPage from "./components/auth/AdminSignUpPage"
import AdminSignInPage from "./components/auth/AdminSignInPage";

// Client pages
import ClientSignUpAcceptTCPage from "./components/authenticated/client/ClientSignUpAcceptTCPage";
import ClientSignUpPrivateVideoRequestPage from "./components/authenticated/client/ClientSignUpPrivateVideoRequestPage";
import ClientDashboardPage from "./components/authenticated/client/ClientDashboardPage";
import ClientMessagesPage from "./components/authenticated/client/ClientMessagesPage";
import ClientPaymentSuccessPage from "./components/authenticated/client/ClientPaymentSuccessPage";
import ClientPaymentCancelPage from "./components/authenticated/client/ClientPaymentCancelPage";
import ClientPrivateVideoPage from "./components/authenticated/client/ClientPrivateVideoPage";
import ClientPrivateVideoRequestPage from "./components/authenticated/client/ClientPrivateVideoRequestPage";
import ClientSignUpSuccessPage from "./components/authenticated/client/ClientSignUpSuccessPage";

// Provider pages
import ProviderSignUpSelectUsernamePage from "./components/authenticated/provider/ProviderSignUpSelectUsernamePage";
import ProviderSignUpCreateProfilePage from "./components/authenticated/provider/ProviderSignUpCreateProfilePage";
import ProviderSignUpSuccessPage from "./components/authenticated/provider/ProviderSignUpSuccessPage";
import ProviderDashboardPage from "./components/authenticated/provider/ProviderDashboardPage";
import ProviderPublicVideosPage from "./components/authenticated/provider/ProviderPublicVideosPage";
import ProviderPrivateVideosPage from "./components/authenticated/provider/ProviderPrivateVideosPage";
import ProviderOutreachVideosPage from "./components/authenticated/provider/ProviderOutreachVideosPage";
import ProviderSettingsPage from "./components/authenticated/provider/ProviderSettingsPage";
import ProviderMessagesPage from "./components/authenticated/provider/ProviderMessagesPage";
import ProviderVideoSettingsPage from "./components/authenticated/provider/ProviderVideoSettingsPage";

// Admin pages
import AdminDashboardPage from "./components/authenticated/admin/AdminDashboardPage";
import AdminUsersPage from "./components/authenticated/admin/AdminUsersPage";
import AdminPublicVideosPage from "./components/authenticated/admin/AdminPublicVideosPage";
import AdminPrivateVideosPage from "./components/authenticated/admin/AdminPrivateVideosPage";
import AdminOutreachVideosPage from "./components/authenticated/admin/AdminOutreachVideosPage";
import AdminVideoSettingsPage from "./components/authenticated/admin/AdminVideoSettingsPage";

// Components
import ScrollToTop from "./components/ScrollToTop";

// Style sheets
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-gallery-carousel/dist/index.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './style_global.css';
import './style_desktop.css';
import './style_mobile.css';

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <ScrollToTop>
          <Switch>
            {/* Auth pages */}
            <Route path="/client/signup" exact>
              <ClientSignUpPage />
            </Route>
            <Route path="/client/signin" exact>
              <ClientSignInPage />
            </Route>
            <Route path="/provider/signup" exact>
              <ProviderSignUpPage />
            </Route>
            <Route path="/provider/signin" exact>
              <ProviderSignInPage />
            </Route>
            <Route path="/admin/signup" exact>
              <AdminSignUpPage />
            </Route>
            <Route path="/admin/signin" exact>
              <AdminSignInPage />
            </Route>

            {/* Client pages */}
            <PrivateClientRoute path="/client/signup/acceptTC" exact>
              <ClientSignUpAcceptTCPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/signup/private" exact>
              <ClientSignUpPrivateVideoRequestPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/dashboard" exact>
              <ClientDashboardPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/messages" exact>
              <ClientMessagesPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/payment/success" exact>
              <ClientPaymentSuccessPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/payment/cancel" exact>
              <ClientPaymentCancelPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/signup/success" exact>
              <ClientSignUpSuccessPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/private" exact>
              <ClientPrivateVideoRequestPage />
            </PrivateClientRoute>
            <PrivateClientRoute path="/client/private/:reqID" exact>
              <ClientPrivateVideoPage />
            </PrivateClientRoute>

            {/* Provider pages */}
            <PrivateProviderRoute path="/provider/signup/username" exact>
              <ProviderSignUpSelectUsernamePage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/signup/profile" exact>
              <ProviderSignUpCreateProfilePage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/signup/success" exact>
              <ProviderSignUpSuccessPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/dashboard" exact>
              <ProviderDashboardPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/public" exact>
              <ProviderPublicVideosPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/private" exact>
              <ProviderPrivateVideosPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/outreach" exact>
              <ProviderOutreachVideosPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/settings" exact>
              <ProviderSettingsPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/messages" exact>
              <ProviderMessagesPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/video/:videoID" exact>
              <ProviderVideoSettingsPage />
            </PrivateProviderRoute>
            <PrivateProviderRoute path="/provider/private/:reqID">
              {/* The provider's private video preview page is just the client private video page, with ?preview=true passed as a param. */}
              <ClientPrivateVideoPage />
            </PrivateProviderRoute>

            {/* Admin pages */}
            <PrivateAdminRoute path="/admin/dashboard" exact>
              <AdminDashboardPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/users" exact>
              <AdminUsersPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/public" exact>
              <AdminPublicVideosPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/private" exact>
              <AdminPrivateVideosPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/outreach" exact>
              <AdminOutreachVideosPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/video/:videoID" exact>
              <AdminVideoSettingsPage />
            </PrivateAdminRoute>
            <PrivateAdminRoute path="/admin/private/:reqID">
              {/* The admin's private video preview page is just the client private video page, with ?preview=true passed as a param. */}
              <ClientPrivateVideoPage />
            </PrivateAdminRoute>

            {/* Public pages */}
            <Route path="/about" exact>
              <AboutPage />
            </Route>
            <Route path="/public/gallery" exact>
              <PublicGalleryPage />
            </Route>
            <Route path="/privacy" exact>
              <PrivacyPage />
            </Route>
            <Route path="/social/invite">
              <SocialInvitePage />
            </Route>
            <Route path="/terms" exact>
              <TermsConditionsPage />
            </Route>
            <Route path="/unsubscribe">
              <UnsubscribePage />
            </Route>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/profile/:username" exact>
              <ProviderProfilePage />
            </Route>
            <Route path="/outreach/:reqID">
              <OutreachVideoPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    </ProvideAuth>
  );
}
