import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  ListGroup,
} from "react-bootstrap";
import {
  useAuth,
} from "../../auth/shared";
import VideoTableCell from "../VideoTableCell";

const axios = require('axios').default;

export default function AdminVideosList(props) {
  const history = useHistory();
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [videos, setVideos] = useState([]);
  const [lastUpdatedAtMsVideos, setLastUpdatedAtMsVideos] = useState(Date.now());

  // API

  useEffect(() => {
    const fetchData = async () => {
      let res = await axios.get([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/videos/public", props.type].join("/"), {
        headers,
      });
      setVideos(res.data);
    }

    fetchData();
  }, [lastUpdatedAtMsVideos]); // eslint-disable-line react-hooks/exhaustive-deps

  // table

  const onVideoCellClick = (video) => {
    history.push({
      pathname: "/admin/video/" + video.id,
      video,
    });
  }

  return (
    <ListGroup as="ul">
      {videos.map(video =>
        <VideoTableCell
          key={video.id}
          video={video}
          onClick={onVideoCellClick}
        />
      )}
    </ListGroup>
  )
}
