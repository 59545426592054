import React, {
  useState,
  useRef,
} from 'react';
import {
  useAuth,
} from "../../auth/shared";
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';

const axios = require('axios').default;

export default function AdminCreateOutreachVideoModal(props) {
  const auth = useAuth();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [name, setName] = useState("");
  const [context, setContext] = useState("");
  const [campaign, setCampaign] = useState("");
  const [providerID, setProviderID] = useState(null);
  const [replicatedReqID, setReplicatedReqID] = useState(null);
  const [numReplications, setNumReplications] = useState(1);
  const cancelTokenSource = useRef();

  const onSubmitForm = (e) => {
    const putData = async () => {
      const data = {
        name,
        context,
        campaign,
        providerID,
        replicatedReqID,
        numReplications,
      };

      cancelTokenSource.current = axios.CancelToken.source();

      try {
        await axios.post([process.env.REACT_APP_API_ADMIN_ENDPOINT, "admin/outreach"].join("/"),
          data, {
          headers,
        }
        );
      } catch (e) {
        console.log("onSubmitForm: e=", e);
        return
      }

      props.onSuccess();
      resetModal();
    }

    e.preventDefault();
    putData();
  }

  const resetModal = () => {
    setName("");
    setContext("");
    setCampaign("");
    setProviderID(null);
  }

  const onCancel = (e) => {
    props.onCancel();
    resetModal();
  }

  if (!props.providers) {
    return null
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Create Video Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            controlId="formRecipientname"
            className="formGroup"
          >
            <Form.Label>Recipient's name: </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onInput={e => setName(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
          <Form.Group
            controlId="formContext"
            className="formGroup"
          >
            <Form.Label>Context: </Form.Label>
            <Form.Control
              type="text"
              name="context"
              value={context}
              onInput={e => setContext(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
          <Form.Group
            controlId="campaign"
            className="formGroup"
          >
            <Form.Label>Campaign</Form.Label>
            <Form.Control
              as="select"
              onChange={e => setCampaign(e.target.value)}
            >
              <option
                id="default-campaign"
                value=""
              >
                select campaign
              </option>
              <option
                id="facebook"
                value="facebook"
              >
                Facebook
              </option>
              <option
                id="topic"
                value="topic"
              >
                Topic
              </option>
            </Form.Control>
          </Form.Group>
          <Form.Group
            controlId="provider"
            className="formGroup"
          >
            <Form.Label>Provider</Form.Label>
            <Form.Control
              as="select"
              onChange={e => setProviderID(e.currentTarget.value)}
            >
              <option
                id="default-provider"
                value={null}
              >
                select provider
              </option>
              <option
                id="replicatedReq"
                value="replicatedReq"
              >
                replicate existing request
              </option>

              {props.providers.map((provider) => (
                <option
                  id={provider.id}
                  key={provider.id}
                  value={provider.id}
                  label={provider.profile.nameFirst + " " + provider.profile.nameLast}
                  onChange={e => setProviderID(provider.id)}
                />
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group
            controlId="formReplicatedReqID"
            hidden={(providerID !== "replicatedReq")}
            className="formGroup"
          >
            <Form.Label>ID of request to replicate: </Form.Label>
            <Form.Control
              type="text"
              name="replicatedReqID"
              value={replicatedReqID}
              onInput={e => setReplicatedReqID(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
          <Form.Group
            controlId="formNumReplications"
            hidden={(providerID !== "replicatedReq")}
            className="formGroup"
          >
            <Form.Label>Number of replications to create: </Form.Label>
            <Form.Control
              type="number"
              name="numReplications"
              value={numReplications}
              onInput={e => setNumReplications(e.target.value)}
              onChange={() => { }}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmitForm}
          disabled={((name === "") || (context === "") || (campaign === "") || !providerID ||
                     ((providerID === "replicatedReq") && !replicatedReqID))}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
