import React from "react";
import {
  useAuth,
} from "./auth/shared";
import ClientAuthHeader from './auth/ClientAuthHeader';
import ProviderAuthHeader from './auth/ProviderAuthHeader';
import AdminAuthHeader from './auth/AdminAuthHeader';
import {
  Nav,
} from 'react-bootstrap';

export default function HeaderMenu() {
  const auth = useAuth();

  if (auth.isProviderSignedIn) {
    return (
      <ProviderAuthHeader />
    )
  }
  if (auth.isAdminSignedIn) {
    return (
      <AdminAuthHeader />
    )
  }
  if (auth.isClientSignedIn) {
    return (
      <ClientAuthHeader />
    )
  }

  return (
    <Nav className="navContainer">
      <Nav.Link
        className="navLink"
        href="/client/signup"
      >
        Sign Up
      </Nav.Link>
      <Nav.Link
        className="navLink"
        href="/client/signin"
      >
        Sign In
      </Nav.Link>
    </Nav>
  )
}