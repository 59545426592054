import React from "react";
import {
  Button,
  Modal,
} from 'react-bootstrap';

export default function ConfirmationModal(props) {
  return (
    <Modal
      show={props.show}
    >
      <Modal.Header>
        <Modal.Title>{props.titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.bodyText}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => props.onCancel()}
        >
          {props.cancelText}
        </Button>
        <Button
          variant="danger"
          onClick={() => props.onConfirm()}
        >
          {props.confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
