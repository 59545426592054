import React, {
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Button,
} from 'react-bootstrap';
import {
  useAuth,
} from "./shared";
import SignInErrorModal from './SignInErrorModal';
import googleSigninButton from "../../assets/btn_google_signin_light_normal_web@2x.png";


export default function ProviderSignInButton() {
  const [showModal, setShowModal] = useState(false);

  let history = useHistory();
  let auth = useAuth();

  let login = async (e) => {
    e.preventDefault();
    let provider = null;
    try {
      provider = await auth.providerSignIn();
    } catch (e) {
      console.log("login error, e=", e);
      setShowModal(true);
      return
    }

    if (!provider.username) {
      history.replace("/provider/signup/username");
    } else if (!provider.profile.bioShort) {
      history.replace("/provider/signup/profile");
    } else {
      history.replace("/provider/dashboard");
    }
  }

  return (
    <div>
      <SignInErrorModal
        show={showModal}
        dismissModal={() => setShowModal(false)}
        signUpLink="/provider/signup"
      />
      <h4>Sign in as a Virtual Hugs provider here:</h4>
      <img
        className="signInButton"
        src={googleSigninButton}
        alt="Google signin button"
        onClick={login}
      />
      <p>Don't have a provider account? Sign up&nbsp;
        <Button
          className="button-link"
          onClick={() => history.replace("/provider/signup")}
        >
          here
        </Button>.
      </p>
      <p>Not a provider? You probably want member signup&nbsp;
        <Button
          className="button-link"
          onClick={() => history.replace("/client/signup")}
        >
          here
        </Button>.
      </p>
    </div>
  )
}
