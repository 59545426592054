import React from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  ListGroup,
} from "react-bootstrap";
import { ReactComponent as LogoIcon } from '../../assets/vhLogo.svg';

export default function OutreachFooter() {
  const history = useHistory();

  return (
    <footer className="outreachFooter">
      <div className="outreachFooterLogoContainer">
        <LogoIcon className="App-logo" />
        <div className="columnCenteredVertically">
          <h3>
            Virtual Hugs
          </h3>
          <h5>
            Videos for Emotional Support
          </h5>
        </div>
      </div>
      <div className="listGroupContainerRow">
        <ListGroup className="listGroupColumn">
          <ListGroup.Item
            className="listGroupItem"
            key="terms"
          >
            <a
              href="/terms"
              className="footerLink"
            >
              Terms & Conditions
            </a>
          </ListGroup.Item>
          <ListGroup.Item
            className="listGroupItem"
            key="privacy"
          >
            <a
              href="/privacy"
              className="footerLink"
            >
              Privacy Policy
            </a>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup className="listGroupColumn">
        <ListGroup.Item
            className="listGroupItem"
            key="about"
          >
            <a
              href="/about"
              className="footerLink"
            >
              About Us
            </a>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="footerCopyright">
        &copy; 2023 Good Struggle LLC, all rights reserved.
      </div>
    </footer>
  )
}
