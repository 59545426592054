import React from 'react';
import {
  Nav,
  Navbar,
} from 'react-bootstrap';
import HeaderMenu from "./HeaderMenu";
import { ReactComponent as LogoIcon } from '../assets/vhLogo.svg';

const renderToggle = (hideMenu) => {
  if (hideMenu) {
    return null
  }

  return (
    <Navbar.Toggle id="navbar-collapsed"
      aria-controls="basic-navbar-nav"
    />
  )
}

const renderMenu = (hideMenu) => {
  if (hideMenu) {
    return null
  }

  return (
    <HeaderMenu />
  )
}

export default function Header(props) {
  return (
    <Navbar bg="transparent" expand="lg">
      <Navbar.Brand href="/">
        <LogoIcon className="App-logo" />
        Virtual Hugs
      </Navbar.Brand>
      {renderToggle(props.hideMenu)}
      <Navbar.Collapse id="basic-navbar-nav">
        {renderMenu(props.hideMenu)}
      </Navbar.Collapse>
    </Navbar>
  )
}