import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
} from "react-router-dom";
import Header from "../../Header";
import {
  useAuth,
} from "../../auth/shared";
import {
  useQuery,
} from "../../../utils/query";
import ClientPrivateVideoRequestForm from "./ClientPrivateVideoRequestForm";
import ResultAlert from "../ResultAlert";

const axios = require('axios').default;

export default function ClientPrivateVideoRequestPage() {
  const auth = useAuth();
  const history = useHistory();
  const query = useQuery();
  const headers = {
    Authorization: "Bearer " + auth.jwt,
  }

  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [provider, setProvider] = useState(null);

  const providerID = query.get("providerID");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get([process.env.REACT_APP_API_CLIENT_ENDPOINT, "provider", providerID].join("/"), {
        headers,
      });
      setProvider(res.data.provider);
    }

    if (providerID) {
      fetchData();
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitForm = (context1, context2) => {
    const callEndpoint = async () => {
      const data = {
        providerID,
        context1,
        context2,
      };

      let res = null;
      try {
        res = await axios.post([process.env.REACT_APP_API_CLIENT_ENDPOINT, "client/private"].join("/"),
          data, {
            headers,
          }
        );
      } catch (e) {
        setShowFailureAlert(true);
        return
      }

      if (res.data.paymentURL) {
        window.location.replace(res.data.paymentURL);
      }
    }

    callEndpoint();
  }

  const onCancelForm = () => {
    history.goBack();
  }

  if (!provider) {
    return null
  }

  return (
    <div className="page">
      <Header />
      <h1 className="pageHeader">
        Request a Private Video
      </h1>
      <ResultAlert
        show={showFailureAlert}
        variant="danger"
        message="Hmm... that didn't work. Count to 3 and try again?"
        onDismiss={() => setShowFailureAlert(false)}
      />
      <div className="nextStepsContainer">
        <div className="nextStep1Container">
          <div className="nextStepText">
            <ClientPrivateVideoRequestForm
              providerNameFirst={provider.profile.nameFirst}
              priceCents={provider.privateVideoPriceCents}
              onSubmit={onSubmitForm}
              onCancel={onCancelForm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
