import React, {
  useEffect,
  useState,
} from "react";
import {
  useGoogleLogin,
} from 'react-use-googlelogin';
import {
  LS_KEY_ACCOUNT_CREATION_SRC,
  LS_KEY_JWT,
  LS_KEY_OUTREACH_REQ_ID,
  LS_KEY_SIGNUP_ID,
  LS_KEY_USER_TYPE,
} from "../../utils/globals";

// References:
// https://reactrouter.com/web/example/auth-workflow
// https://usehooks.com/useAuth/

const axios = require('axios').default;
const authContext = React.createContext();

const useProvideAuth = () => {
  const [loaded, setLoaded] = useState(false);
  const [jwt, setJwt] = useState(null);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const myJwt = localStorage.getItem(LS_KEY_JWT);
    setJwt(myJwt);

    const myUserType = localStorage.getItem(LS_KEY_USER_TYPE);
    setUserType(myUserType);

    setLoaded(true);
  }, []);

  // The user is signed in if:
  // (1) they're signed in through Google
  // (2) the backend returned a JWT
  // (3) the correct user type is specified
  const googleAuth = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  });
  const initialized = loaded && googleAuth.isInitialized;
  const isClientSignedIn = googleAuth.isSignedIn && (jwt !== null) && (userType === "client");
  const isProviderSignedIn = googleAuth.isSignedIn && (jwt !== null) && (userType === "provider");
  const isAdminSignedIn = googleAuth.isSignedIn && (jwt !== null) && (userType === "admin");

  const apiEndpointForUserType = (userType) => {
    switch (userType) {
      case "admin":
        return process.env.REACT_APP_API_ADMIN_ENDPOINT
      case "client":
        return process.env.REACT_APP_API_CLIENT_ENDPOINT
        case "provider":
          return process.env.REACT_APP_API_PROVIDER_ENDPOINT
      }

    return null
  }

  const commonSignIn = async (userType) => {
    let user = await googleAuth.signIn();

    const endpoint = apiEndpointForUserType(userType);

    const data = {
      profileObj: user.profileObj,
      tokenObj: user.tokenObj,
    }
    let res = await axios.post([endpoint, userType, "signin"].join("/"),
      data,
    )

    // Save the user type (for client-side authorization) and JWT (for API calls).
    // FUTURE: Don't store JWT in local storage for security reasons.
    const myJwt = res.data.jwt;
    setJwt(myJwt);
    localStorage.setItem(LS_KEY_JWT, myJwt);

    const myUserType = res.data.userType;
    setUserType(myUserType);
    localStorage.setItem(LS_KEY_USER_TYPE, myUserType);

    return (res.data)
  }

  const commonSignUp = async (userType) => {
    const user = await googleAuth.signIn();

    const data = {
      profileObj: user.profileObj,
      tokenObj: user.tokenObj,
    }

    const signupID = localStorage.getItem(LS_KEY_SIGNUP_ID);
    localStorage.removeItem(LS_KEY_SIGNUP_ID);
    if (signupID) {
      data.signupID = signupID;
    }

    const accountCreationSrc = localStorage.getItem(LS_KEY_ACCOUNT_CREATION_SRC);
    localStorage.removeItem(LS_KEY_ACCOUNT_CREATION_SRC);
    if (accountCreationSrc) {
      data.accountCreationSrc = accountCreationSrc;
    }
    const outreachReqID = localStorage.getItem(LS_KEY_OUTREACH_REQ_ID);
    localStorage.removeItem(LS_KEY_OUTREACH_REQ_ID);
    if (outreachReqID) {
      data.outreachReqID = outreachReqID;
    }

    const endpoint = apiEndpointForUserType(userType);

    const res = await axios.post([endpoint, userType, "signup"].join("/"),
      data,
    )

    // Save the user type (for client-side authorization) and JWT (for API calls).
    // FUTURE: Don't store JWT in local storage for security reasons.
    const myJwt = res.data.jwt;
    setJwt(myJwt);
    localStorage.setItem(LS_KEY_JWT, myJwt);

    const myUserType = res.data.userType;
    setUserType(myUserType);
    localStorage.setItem(LS_KEY_USER_TYPE, myUserType);
  }

  const commonSignOut = async () => {
    setJwt(null);
    localStorage.removeItem(LS_KEY_JWT);

    setUserType(null);
    localStorage.removeItem(LS_KEY_USER_TYPE);

    return googleAuth.signOut();
  }

  return {
    initialized,
    jwt,
    clientSignIn: () => commonSignIn("client"),
    clientSignUp: () => commonSignUp("client"),
    clientSignOut: () => commonSignOut(),
    isClientSignedIn,
    providerSignIn: () => commonSignIn("provider"),
    providerSignUp: () => commonSignUp("provider"),
    providerSignOut: () => commonSignOut(),
    isProviderSignedIn,
    adminSignIn: () => commonSignIn("admin"),
    adminSignUp: () => commonSignUp("admin"),
    adminSignOut: () => commonSignOut(),
    isAdminSignedIn,
  }
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(authContext);
}
